import request from "@utils/request";

const urlParams = {
  policyDeptList: "/policy/dept/list", // 获取所有部门
  policyDeptGet: "/policy/dept/get", // 获取该部门有哪些权限
  policyDeptSet: "/policy/dept/set", // 设置权限
};

// 查询所有系统配置
export default {
  policyDeptList() {
    return request({
      url: urlParams.policyDeptList,
      method: "get",
    });
  },
  policyDeptGet(data) {
    return request({
      url: urlParams.policyDeptGet,
      method: "get",
      params: data,
    });
  },
  policyDeptSet(data) {
    return request({
      url: urlParams.policyDeptSet,
      method: "post",
      data,
    });
  },
};
