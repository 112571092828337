import store from "@/store";
import Vue from "vue";
import csvToJson from "csvtojson";
const vm = new Vue();


// 下载url文件,并重命名
export const downloadFileName = (url, filename)=> {
  getBlob(url).then((blob) => {
    saveAs(blob, filename);
  })
	function getBlob(url) {
	  return new Promise((resolve) => {
	    const xhr = new XMLHttpRequest();
	    xhr.open('GET', url, true);
	    xhr.responseType = 'blob';
	    xhr.onload = () => {
	      if (xhr.status === 200) {
	        resolve(xhr.response);
	      }
	    };
	    xhr.send();
	  });
	}
	function saveAs(blob, filename) {
	  if (window.navigator.msSaveOrOpenBlob) {
	    navigator.msSaveBlob(blob, filename);
	  } else {
	    const link = document.createElement('a');
	    const body = document.querySelector('body');
	    link.href = window.URL.createObjectURL(blob);
	    link.download = filename;
	    link.style.display = 'none';
	    body.appendChild(link);
	    link.click();
	    body.removeChild(link);
	    window.URL.revokeObjectURL(link.href);
	  }
	}
}

 // json转csv
export const convertToCSV = (json)=> {
  const items = JSON.parse(json);
  const headers = Object.keys(items[0]);
  const csvHeader = headers.join(",");
  const csvBody = items
    .map((item) => {
      return headers
        .map((header) => {
          return item[header];
        })
        .join(",");
    })
    .join("\r\n");
  const csv = `${csvHeader}\r\n${csvBody}`;
  return csv;   
}
    
export const getJsonFromCsv = async (csv) => {
  return await csvToJson().fromString(csv);
};

export const recursionMenuList = (sidebarMenu, level = 0) => {
  var realRoutes = [];
  sidebarMenu.forEach((item, i) => {
    if (item.children && item.children.length > 0) {
      item.children = recursionMenuList(item.children, ++level);
    }
    item.title = item.meta.title;
    item.icon = item.meta.icon;
    item.key = `${i}${new Array(level).fill(`_${i}`)}`;
    realRoutes.push(item);
  });
  return realRoutes;
};
export const GetRequest = () => {
  var url = location.search;

  //获取url中"?"符后的字串

  var theRequest = new Object();

  if (url.indexOf("?") != -1) {
    const str = url.slice(1);

    const strs = str.split("&");

    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = decodeURIComponent(
        strs[i].split("=")[1]
      );
    }
  }
  return theRequest;
};

const getComPermission = (permissionList, comPermission = []) => {
  if (!Array.isArray(permissionList)) {
    return comPermission;
  }
  permissionList.forEach((item) => {
    if (item.assembly_list) {
      comPermission.push(item.assembly_list.map((al) => al.code));
    }
    if (item.child_menu && item.child_menu.length) {
      getComPermission(item.child_menu, comPermission);
    }
  });
  return comPermission.flat(1);
};
// eslint-disable-next-line no-unused-vars
export const hasPermission = (code, showError = true) => {
  const permissionList = store.state.permissions.permissionListCodes;
  // console.log(permissionList);
  const comPermission = getComPermission(permissionList);
  // console.log(permissionList, comPermission);
  if (!comPermission.includes(code)) {
    if (showError) {
      vm.$message.warning("你没有权限操作");
    }

    return false;
  }
  return true;
};

export const getRandomNumberByRange = (start, end) => {
  return Math.floor(Math.random() * (end - start) + start);
};
export function csvJSON(csv) {
  let lines = csv.split("\n");

  let result = [];

  let headers = lines[0].split(",");

  for (let i = 1; i < lines.length; i++) {
    let obj = {};
    let currentline = lines[i].split(",");

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }

    result.push(obj);
  }

  return result;
}
export function downImage(data, name) {
  const blob = new Blob([data], {
    type: "image/png"
  });
  const downloadElement = document.createElement("a");
  const href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  downloadElement.download = name;
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
  window.URL.revokeObjectURL(href);
}
export const handleExportFile = function (data, name) {
  const blob = new Blob([data], {
    type: "application/vnd.ms-excel;charset=utf-8",
  });
  const downloadElement = document.createElement("a");
  const href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  downloadElement.download = name;
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
  window.URL.revokeObjectURL(href);
};
export function downloadVideo(url, fileName = "video") {
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      const a = document.createElement("a");
      const objectUrl = window.URL.createObjectURL(blob);
      a.download = fileName;
      a.href = objectUrl;
      a.click();
      window.URL.revokeObjectURL(objectUrl);
      a.remove();
    });
}

/**
 * 时间格式返回
 * @param {String} value
 */
export const returnDate = (value) => {
  let v = {
    date: new Date(), //时间
    timeStamp: Date.parse(new Date()), //时间戳-精确到秒
    year: "", //2019
    month: "", //02
    yearMonth: "", //2019-08
    monthData: "", //08-08
    monthGData: "", //08/08
    numDate: "", //2019-08-08
    evenDate: "", //2019/08/08
    strDate: "", //2019年08月08日
    time: "", //2019-08-08 12:12
    timeAll: "", //2019-08-08 12:12:12
    allWeekDate: "", // 08月08日 星期二 10:10:28
  };
  if (value) {
    // eslint-disable-next-line no-useless-escape
    v.date = new Date(value.replace(/\-/g, "/"));
    v.timeStamp = Date.parse(v.date);
  }
  let t = v.date;
  let y = t.getFullYear();
  let m = t.getMonth() + 1;
  let d = t.getDate();
  let h = t.getHours();
  let s = t.getMinutes();
  let min = t.getSeconds();
  v.year = y;
  v.month = makeUp(m);
  v.date = d;
  v.MD = `${makeUp(m)}月${makeUp(d)}日`;
  v.yearMonth = `${y}-${makeUp(m)}`;
  v.monthData = `${makeUp(m)}-${makeUp(d)}`;
  v.monthGData = `${makeUp(m)}/${makeUp(d)}`;
  v.numDate = `${y}-${makeUp(m)}-${makeUp(d)}`;
  v.evenDate = `${y}/${makeUp(m)}/${makeUp(d)}`;
  v.strDate = `${y}年${makeUp(m)}月${makeUp(d)}日`;
  v.time = `${y}-${makeUp(m)}-${makeUp(d)} ${makeUp(h)}:${makeUp(s)}`;
  v.timeAll = `${y}-${makeUp(m)}-${makeUp(d)} ${makeUp(h)}:${makeUp(
    s
  )}:${makeUp(min)}`;
  v.allWeekDate = `${y}.${makeUp(m)}.${makeUp(d)} ${getMyWeek()} ${makeUp(
    h
  )}:${makeUp(s)}:${makeUp(min)}`;

  return v;
};

/**
 * 日期时间补0
 * @param {Number} s
 */
export const makeUp = (s) => {
  return s < 10 ? "0" + s : s;
};

/**
 * 日期转换为星期
 * @param {String} d
 */
export const getMyWeek = () => {
  let w;
  if (new Date().getDay() == 0) w = "星期日";
  if (new Date().getDay() == 1) w = "星期一";
  if (new Date().getDay() == 2) w = "星期二";
  if (new Date().getDay() == 3) w = "星期三";
  if (new Date().getDay() == 4) w = "星期四";
  if (new Date().getDay() == 5) w = "星期五";
  if (new Date().getDay() == 6) w = "星期六";
  return w;
};


//判断浏览器是否处于全屏状态 （需要考虑兼容问题）
export const checkFullscreen = () => {
  let isFull =
    document.mozFullScreen ||
    document.fullScreen ||
    document.webkitIsFullScreen ||
    document.webkitRequestFullScreen ||
    document.mozRequestFullScreen ||
    document.msFullscreenEnabled;
  return !!isFull;
};

// 全屏
export const launchIntoFullscreen = (element) => {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
};

// 退出全屏
export const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
};

// 金额转换
export const handleMoney = (num) => {
  // 首先先声明一个金额单位数组
  let AmountUnitlist = ["亩", "万亩", "亿亩"];
  // 将数字金额转为字符串
  let strnum = num.toString();
  // 声明一个变量用于接收金额单位
  let AmountUnit = "";
  // 循环遍历单位数组
  AmountUnitlist.find((item) => {
    let newNum = "";
    // 判断一下传进来的金额是否包含小数点
    if (strnum.indexOf(".") !== -1) {
      // 若有则将小数点前的字符截取出来
      newNum = strnum.substring(0, strnum.indexOf("."));
    } else {
      // 没有则直接等于原金额
      newNum = strnum;
    }
    // 判断一下经过小数点截取后的金额字符长度是否小于5
    if (newNum.length < 5) {
      // 若小于5则接收当前单位，并跳出迭代
      AmountUnit = item;
      return true;
    } else {
      // 若不小于5则将经过小数点截取处理过后的字符除以10000后作为下一轮迭代的初始金额重新判断(每一个单位之间相距4位数，故除以10000)
      strnum = ((newNum * 1) / 10000).toString();
    }
  });
  let money = {
    num: 0,
    unit: "",
  };
  // 保留2位小数
  money.num = (strnum * 1).toFixed(2);
  // 接收单位
  money.unit = AmountUnit;
  return money;
};

// 亩数转换
export const handleAcre = (num) => {
  // 首先先声明一个单位数组
  let AmountUnitlist = ["亩", "万亩", "亿亩"];
  // 将数字转为字符串
  let strnum = num.toString();
  // 声明一个变量用于接收单位
  let AmountUnit = "";
  // 循环遍历单位数组
  AmountUnitlist.find((item) => {
    let newNum = "";
    // 判断一下传进来的金额是否包含小数点
    if (strnum.indexOf(".") !== -1) {
      // 若有则将小数点前的字符截取出来
      newNum = strnum.substring(0, strnum.indexOf("."));
    } else {
      // 没有则直接等于原金额
      newNum = strnum;
    }
    // 判断一下经过小数点截取后的金额字符长度是否小于5
    if (newNum.length < 5) {
      // 若小于5则接收当前单位，并跳出迭代
      AmountUnit = item;
      return true;
    } else {
      // 若不小于5则将经过小数点截取处理过后的字符除以10000后作为下一轮迭代的初始金额重新判断(每一个单位之间相距4位数，故除以10000)
      strnum = ((newNum * 1) / 10000).toString();
    }
  });
  let money = {
    num: 0,
    unit: "",
  };
  // 保留1位小数
  money.num = (strnum * 1).toFixed(1);
  // 接收单位
  money.unit = AmountUnit;
  return money;
};

/**
 * 数字转成汉字
 * @params num === 要转换的数字
 * @return 汉字
 * */
export const toChinesNum = (num) => {
  let changeNum = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
  let unit = ["", "十", "百", "千", "万"];
  num = parseInt(num);
  let getWan = (temp) => {
    let strArr = temp.toString().split("").reverse();
    let newNum = "";
    let newArr = [];
    strArr.forEach((item, index) => {
      newArr.unshift(
        item === "0" ? changeNum[item] : changeNum[item] + unit[index]
      );
    });
    let numArr = [];
    newArr.forEach((m, n) => {
      if (m !== "零") numArr.push(n);
    });
    if (newArr.length > 1) {
      newArr.forEach((m, n) => {
        if (newArr[newArr.length - 1] === "零") {
          if (n <= numArr[numArr.length - 1]) {
            newNum += m;
          }
        } else {
          newNum += m;
        }
      });
    } else {
      newNum = newArr[0];
    }

    return newNum;
  };
  let overWan = Math.floor(num / 10000);
  let noWan = num % 10000;
  if (noWan.toString().length < 4) {
    noWan = "0" + noWan;
  }
  return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
};