<template>
  <div class="chart" :style="{ height: height }">
    <div class="flex flex-column justify-center align-center" :style="{ height: height, width: width }"
      v-show="JSON.stringify(chartOption) === '{}'">
      <img :src="chartType === 'bar'
        ? require('@/assets/404_images/empty_bar.png')
        : chartType === 'line' ? require('@/assets/404_images/empty_line.png') : chartType === 'pie'
          ? require('@/assets/404_images/empty_pie.png')
          : require('@/assets/404_images/empty_icon.png')
        " :style="imgStyleObj" />
      <span class="f-s-12 m-t-15 color-999">暂无数据</span>
    </div>
    <div ref="chart" :style="{ height: height, width: width }" v-show="chartOption" />
  </div>
</template>

<script>
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart, LineChart, PieChart } from "echarts/charts";
// 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";

// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  PieChart,
  LineChart,
  CanvasRenderer,
  LegendComponent,
]);

export default {
  name: "ChartView",
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "350px",
    },
    autoResize: {
      type: Boolean,
      default: true,
    },
    chartOption: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: "canvas",
    },
    chartType: {
      type: String,
      default: "",
    },
    imgStyleObj: {
      type: Object,
      default: () => {
        return {
          width: "146px",
          opacity: "1",
        };
      },
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    chartOption: {
      deep: true,
      handler(newVal) {
        this.setOptions(newVal);
      },
    },
  },
  mounted() {
    this.initChart();
    if (this.autoResize) {
      window.addEventListener("resize", this.resizeHandler);
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    if (this.autoResize) {
      window.removeEventListener("resize", this.resizeHandler);
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    resizeHandler() {
      this.chart.resize();
    },
    initChart() {
      this.chart = echarts.init(this.$refs.chart, "", {
        renderer: this.type,
      });
      this.chart.setOption(this.chartOption);
      this.chart.on("click", this.handleClick);
    },
    handleClick(params) {
      this.$emit("click", params);
    },
    setOptions(option) {
      this.clearChart();
      this.resizeHandler();
      if (this.chart) {
        this.chart.setOption(option);
      }
    },
    refresh() {
      this.setOptions(this.chartOption);
    },
    clearChart() {
      this.chart && this.chart.clear();
    },
  },
};
</script>

<style lang="less" scoped>
.chart {
  overflow: hidden;
  width: 100%;
  // height: 100%;
}
</style>
