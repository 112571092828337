<template>
  <a-popover
    placement="bottomRight"
    class="head__com__right flex align-center pointer"
    overlayClassName="head__com__popover"
  >
    <template slot="content">
      <div class="user-info flex align-center">
        <img src="@/assets/images/head_img.png" />
        <span class="f-s-16 color-000 m-l-15">{{ userInfo.user_name }}</span>
      </div>
      <div class="other-nav-list flex flex-column">
        <span @click="goPage">个人主页</span>
        <span @click="goPage">系统工具</span>
      </div>
      <div class="exit pointer" @click="exit">退出登录</div>
    </template>
    <img src="@/assets/images/head_img.png" class="head" />
    <span class="f-s-14 color-fff m-l-10">{{ userInfo.user_name }}</span>
  </a-popover>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  methods: {
    ...mapActions(["logout"]),
    //登出功能
    exit() {
      this.$confirm({
        title: "提示",
        content: "确定注销并退出系统吗？",
        centered: true,
        onOk: () => {
          this.logout().then(() => {
            this.$router.push("/login");
          });
        },
        onCancel: () => {},
      });
    },
    //跳转
    goPage() {
      this.$message.warning("敬请期待");
    },
  },
};
</script>

<style lang="less" scoped>
.head__com__right {
  height: 100%;
  &:hover {
    opacity: 0.8;
  }
  .head {
    height: 32px;
  }
}
</style>

<style lang="less">
.head__com__popover {
  padding-top: 0 !important;
  .ant-popover-arrow {
    display: none !important;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
  .user-info {
    width: 360px;
    height: 114px;
    background: url("../../../assets/images/head_user_back.png") no-repeat
      center center;
    img {
      margin-left: 16px;
      height: 50px;
    }
  }
  .other-nav-list {
    line-height: 28px;
    color: #000;
    border-bottom: 1px solid #e7e7e7;
    padding: 10px 8px 15px 8px;
    span {
      cursor: pointer;
      padding: 0 8px;
      margin-top: 10px;
      &:hover {
        background: #f2f3ff;
        color: #4378db;
      }
    }
  }
  .exit {
    padding: 20px 8px 20px 16px;
    color: #d54941;
    &:hover {
      opacity: 0.6;
    }
  }
}
</style>
