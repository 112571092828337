<template>
  <div class="head__com__left flex align-center">
    <img
      src="@/assets/images/header_logo.png"
      class="logo pointer"
      @click="$router.replace('/bigScreen')"
    />
    <a-popover
      placement="bottom"
      class="product__toggle flex align-center pointer"
      overlayClassName="head__nav_popover"
      :visible="hovered"
      @visibleChange="handleHoverChange"
    >
      <template slot="content">
        <div class="menu-list flex color-fff">
          <div
            class="menu-item pointer"
            v-for="(item, index) in listData"
            :key="index"
          >
            <template v-for="(sub, nx) in item">
              <div
                v-if="!sub.meta.hideMenu"
                :key="sub.menu_url"
                @click="onItem(sub, index, nx)"
                class="transition-name"
                :class="{
                  'menu-active': sub.select,
                  'menu-par-active': index === 0 && sub.select,
                }"
              >
                {{ sub.title }}
              </div>
            </template>
          </div>
        </div>
      </template>
      <span class="m-r-10 f-s-16 color-fff">{{
        $route.path === "/bigScreen" ? "产品入口" : sidebarSecondMenu.title
      }}</span>
      <a-icon type="caret-down" style="color: #b1b4c3" />
    </a-popover>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      hovered: false,
      listData: [],
      parSubIndex: 0,
    };
  },
  computed: {
    ...mapState({
      sidebarMenu: (state) => state.permissions.sidebarMenu,
      sidebarSecondMenu: (state) => state.permissions.sidebarSecondMenu,
    }),
  },
  mounted() {
    this.listData = [JSON.parse(JSON.stringify(this.sidebarMenu))];
  },
  methods: {
    handleHoverChange(visible) {
      this.hovered = visible;
    },
    onItem(item, parIndex, subIndex) {
      // 选中一级处理，保存下级数据，并且清空list数据
      if (parIndex === 0) {
        this.listData = [JSON.parse(JSON.stringify(this.sidebarMenu))];
        this.parSubIndex = subIndex;
      }
      // 选中增加样式，
      for (let i = 0; i < this.listData[parIndex].length; i++) {
        let item = this.listData[parIndex][i];
        item["select"] = false;
      }
      this.listData[parIndex][subIndex]["select"] = true;

      // push列表，与页面跳转
      if (this.listData.length > 1) {
        this.listData = this.listData.slice(0, parIndex + 1);
      }
      if (item.children && item.children.length > 0) {
        this.listData.push(item.children);
      } else {
        if (this.listData[0][this.parSubIndex].children) {
          this.$store.commit(
            "permissions/SET_SECOND_MENU",
            this.listData[0][this.parSubIndex]
          );
          this.clearActive();
        }
        this.$router.push(item.menu_url);
      }
    },
    clearActive() {
      for (let i = 0; i < this.listData.length; i++) {
        let item = this.listData[i];
        for (let j = 0; j < item.length; j++) {
          item[j]["select"] = false;
        }
      }
      this.hovered = false;
      window.localStorage.removeItem("systemOpenKeys");
    },
  },
};
</script>

<style lang="less" scoped>
.head__com__left {
  height: 100%;
  .logo {
    height: 30px;
  }
  .product__toggle {
    margin-left: 64px;
    height: 100%;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>

<style lang="less">
.head__nav_popover {
  padding-top: 0 !important;
  width: 100%;
  top: 64px !important;
  .ant-popover-inner-content {
    box-shadow: inset 0 10px 10px rgba(0, 0, 0, 0.2);
    background: rgba(36, 36, 36, 0.9);
  }
  .ant-popover-inner {
    background: none;
  }
  .ant-popover-arrow {
    display: none !important;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
  .menu-list {
    color: rgba(255, 255, 255, 0.6);
    padding: 16px 0 50px 180px;
    .menu-item {
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      padding-left: 60px;
      margin-left: 60px;
      &:first-child {
        border: 0;
        padding-left: 0;
        margin-left: 0;
      }
      > div {
        margin-top: 16px;
        padding-bottom: 16px;
        display: table;
        &:hover,
        &.menu-active {
          color: #6492e7;
        }
        &.menu-par-active {
          border-bottom: 2px solid #6492e7;
        }
      }
    }
  }
}
</style>
