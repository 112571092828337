<template>
  <a-breadcrumb class="layout-breadcrumb">
    <a-breadcrumb-item
      class="breadcrumb-item"
      v-for="item in navList"
      :key="item.meta.icon"
    >
      <span :class="`iconfont ${item.meta.icon}`" v-if="item.meta.icon"></span>
    </a-breadcrumb-item>
    <a-breadcrumb-item
      class="breadcrumb-item"
      v-for="(item, index) in navList"
      :key="item.name"
    >
      <template v-if="item.meta.title">
        <span v-if="item.name != name && index != 1">{{
          item.meta.title
        }}</span>
        <span v-else>{{ item.meta.title }}</span>
      </template>
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script>
export default {
  name: "Crumbs",
  props: {
    otherList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      name: "",
      navList: [],
    };
  },
  watch: {
    $route() {
      this.getCrumb();
    },
    otherList() {
      this.getCrumb();
    },
  },
  mounted() {
    this.getCrumb();
  },
  methods: {
    getCrumb() {
      this.navList = [];
      this.name = this.$route.name;
      this.$route.matched.forEach((item) => {
        this.navList.push(item);
      });
      const newList = this.otherList.map((item) => {
        return {
          meta: {
            title: item,
          },
        };
      });
      this.navList = [...this.navList, ...newList];
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .breadcrumb-item:last-child {
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
}
::v-deep .breadcrumb-item {
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 22px;
}
.layout-breadcrumb {
  padding-bottom: 16px;
  height: 40px;
  &-item {
    color: #7f7f7f;
  }
  &-item:last-child {
    font-size: 16px;
    color: #1d2129;
  }
}
</style>
