import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./BaseComponent/lazy_useUI";
import "@utils/rem";
import "@/icons"; // icon
import api from "./api/index";
import "@/styles/index.less";
import "@/router/permission";
import "@/directives/";
import eChartFn from "@/components/chart/index.js";
import ChartPanel from "@/components/chart/index.vue";
Vue.component(ChartPanel.name, ChartPanel);
Vue.prototype.$eChartFn = eChartFn;

// 全局组件
import "./utils/vueComponent";
// 引入excel
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
// 引入echarts
import * as echarts from "echarts";
import "echarts-gl";

Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.CODE_OK = 200;
Vue.prototype.$setLoading = function (props) {
  if (typeof props === "boolean") props = {
    spinning: props
  };
  if (Object.prototype.toString.call(props) !== "[object Object]") props = {};
  this.$app.loadingProps = {
    tip: "加载中...",
    ...props,
  };
};
Vue.filter("mathFloat", function (value) {
  return typeof value === "number" ? parseFloat(value).toFixed(2) : "";
});
Vue.filter("mathFloat1", function (value) {
  return typeof value === "number" ? parseFloat(value).toFixed(1) : "";
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");