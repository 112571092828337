<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <a-spin size="large" v-bind="loadingProps" class="full-width full-height">
        <router-view></router-view>
      </a-spin>
    </a-config-provider>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import Vue from "vue";
export default {
  data() {
    return {
      locale: zhCN,
      loadingProps: {
        spinning: false,
      },
    };
  },
  computed: {},
  beforeCreate() {
    Vue.prototype.$app = this;
  },
  methods: {
    start() {
      this.$message.error("hahaha");
    },
  },
};
</script>
<style lang="less">
#app {
  width: 100%;
  min-width: 1200px;
  overflow-x: auto;
  height: 100vh;
}
#app .ant-spin-container {
  width: 100%;
  height: 100%;
}
</style>
