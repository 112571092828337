<template>
  <a-table
    :loading="loading"
    :columns="columns"
    :data-source="dataList"
    :locale="locale"
    :scroll="{ y: 'calc(100vh - ' + scrollHeight + 'px)', x: '100.5%' }"
    :rowKey="(record, index) => index"
    :pagination="paginationOpt"
    :row-selection="
      isCheck
        ? {
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }
        : null
    "
  >
    <template slot="action" slot-scope="text, record">
      <slot :record="record"></slot>
    </template>
  </a-table>
</template>

<script>
import tableLocale from "@/mixin/tableLocale";
export default {
  name: "TableTemplate",
  props: {
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    dataList: {
      type: Array,
      default() {
        return [];
      },
    },
    scrollHeight: {
      type: Number,
      default: 350,
    },
    current: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    isCheck: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [tableLocale],
  data() {
    return {
      selectedRowKeys: [],
      paginationOpt: {
        current: this.current, // 默认当前页数
        defaultPageSize: 20, // 默认当前页显示数据的大小
        total: this.total, // 总数，必须先有
        showSizeChanger: true,
        showTotal: (total) => {
          var html = (
            <div class="text flex align-center">
              <div class="text1">共</div>
              <div class="text2">{total}</div>
              <div class="text1">个记录</div>
            </div>
          );
          return html;
        },
        pageSizeOptions: ["20", "30", "40", "50"],
        onShowSizeChange: (current, pageSize) => {
          this.selectedRowKeys = [];
          this.paginationOpt.current = current;
          this.$emit("onPagShowSizeChange", {
            current,
            pageSize,
          });
        },
        // 改变每页数量时更新显示
        onChange: (current, pageSize) => {
          this.selectedRowKeys = [];
          this.paginationOpt.current = current;
          this.$emit("onPagChange", {
            current,
            pageSize,
          });
        },
      },
    };
  },
  watch: {
    total(newVal) {
      this.paginationOpt.total = newVal;
    },
    current(newVal) {
      if (newVal) {
        this.paginationOpt.current = newVal;
      }
    },
    loading(newVal) {
      if (newVal) {
        this.loading = newVal;
      }
    },
  },
  methods: {
    // 批量选择
    onSelectChange(selectedRowKeys, record) {
      this.selectedRowKeys = selectedRowKeys;
      this.$emit("onSelectChange", {
        selectedRowKeys,
        record,
      });
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep .ant-pagination-total-text {
  margin-right: 20px;
  margin-top: 10px;
}
.text {
  .text1 {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 22px;
  }
  .text2 {
    color: #4378db;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
}
</style>
