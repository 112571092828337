import request from "@utils/request";

// 查询所有系统配置
export default {
  list(data) {
    return request({
      url: "/potion/list",
      method: "get",
      params: data,
    });
  },
  addPotion(data) {
    return request({
      url: "/potion/add",
      method: "post",
      data,
    });
  },
  editPotion(data) {
    return request({
      url: "/potion/edit",
      method: "put",
      data,
    });
  },
  deletePotion(data) {
    return request({
      url: "/potion/del",
      method: "delete",
      data,
    });
  },
  mylist(data) {
    return request({
      url: "/company/potion/list",
      method: "get",
      params: data,
    });
  },
};
