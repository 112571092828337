import request from "@utils/request";

// 查询所有系统配置
export default {
  addProductQ(data) {
    return request({
      url: "/product_model/add",
      method: "post",
      data,
    });
  },

  delProductQ(data) {
    return request({
      url: "/product_model/delete",
      method: "delete",
      data,
    });
  },
  infoProductQ(params) {
    return request({
      url: "/product_model/info",
      method: "get",
      params,
    });
  },
  productList(params) {
    return request({
      url: "/product_model/list",
      method: "get",
      params,
    });
  },
  updateProduct(data) {
    return request({
      url: "/product_model/update",
      method: "put",
      data,
    });
  },
  addBrand(data) {
    return request({
      url: "/product_nameplate/add",
      method: "post",
      data,
    });
  },
  listBrand(params) {
    return request({
      url: "/product_nameplate/list",
      method: "get",
      params,
    });
  },
  listProductModel(params) {
    return request({
      url: "/product_nameplate/product_model/list",
      method: "get",
      params,
    });
  },
  qrcode(params) {
    return request({
      url: "/product_nameplate/qrcode",
      method: "get",
      params,
      responseType: "blob",
    });
  },
  dict(id) {
    return request({
      url: "/dictionary/get/" + id,
      method: "get",
    });
  },
};
