const dynamicRoutes = [{
    path: "LanjiangCompany",
    menu_url: "/LanjiangCompany",
    name: "LanjiangCompany",
    meta: {
      title: "岚江客户管理",
      icon: "",
      code: "lj_company"
    },
    component: () => import("@/views/LanjiangCompany/"),
    redirect: "/LanjiangCompany/UnitMa",
    children: [{
        path: "/LanjiangCompany/UnitMa",
        menu_url: "/LanjiangCompany/UnitMa",
        name: "UnitMa",
        meta: {
          title: "直销客户",
          icon: "icon-a-24pxzhixiaokehu",
          code: "	lj_company_zx",
        },
        component: () => import("@/views/LanjiangCompany/UnitMa"),
        redirect: "/LanjiangCompany/UnitMa/UserInfo",
        children: [{
            path: "UserInfo",
            menu_url: "/LanjiangCompany/UnitMa/UserInfo",
            name: "UserInfo",
            meta: {
              title: "企业信息管理",
              icon: "",
              code: "lj_company_zx_user",
            },
            component: () => import("@/views/LanjiangCompany/UnitMa/UserInfo"),
          },
          {
            path: "CompanyUserInfo",
            menu_url: "/LanjiangCompany/UnitMa/CompanyUserInfo",
            name: "company_userInfo",
            meta: {
              title: "用户信息管理",
              icon: "",
              code: "lj_company_zx_userInfo",
            },
            component: () =>
              import("@/views/LanjiangCompany/UnitMa/CompanyUser"),
          },
        ],
      },
      {
        path: "Agent",
        menu_url: "/LanjiangCompany/Agent",
        name: "Agent",
        meta: {
          title: "代理商客户",
          icon: "icon-a-16pxdailishang",
          code: "lj_agency",
        },
        component: () => import("@/views/LanjiangCompany/Agent/"),
        redirect: "/LanjiangCompany/Agent/AgentInfo",
        children: [{
            path: "AgentInfo",
            menu_url: "/LanjiangCompany/Agent/AgentInfo",
            name: "AgentInfo",
            meta: {
              title: "代理商信息管理",
              icon: "",
              code: "lj_agency_Info"
            },
            component: () => import("@/views/LanjiangCompany/Agent/AgentInfo"),
          },
          {
            path: "CustomInfo",
            menu_url: "/LanjiangCompany/Agent/CustomInfo",
            name: "CustomInfo",
            meta: {
              title: "代理商客户信息管理",
              icon: "",
              code: "lj_custom_Info",
            },
            component: () => import("@/views/LanjiangCompany/Agent/CustomInfo"),
          },
        ],
      },
    ],
  },
  {
    path: "OpplatForm",
    menu_url: "/OpplatForm",
    name: "OpplatForm",
    meta: {
      title: "岚江开放平台",
      icon: ""
    },
    component: () => import("@/views/OpplatForm/"),
    redirect: "/OpplatForm/KeyManage",
    children: [{
        path: "KeyManage",
        menu_url: "/OpplatForm/KeyManage",
        name: "KeyManage",
        meta: {
          title: "密钥管理",
          icon: "icon-lock-on"
        },
        component: () => import("@/views/OpplatForm/KeyManage"),
      },
      {
        path: "WhiteManage",
        menu_url: "/OpplatForm/WhiteManage",
        name: "WhiteManage",
        meta: {
          title: "白名单管理",
          icon: "icon-baimingdan"
        },
        component: () => import("@/views/OpplatForm/WhiteManage"),
      },
    ],
  },
  {
    path: "SystemTool",
    menu_url: "/SystemTool",
    name: "SystemTool",
    meta: {
      title: "系统管理",
      icon: "",
      code: "system"
    },
    component: () => import("@/views/SystemTool/"),
    redirect: "/SystemTool/MenuRole",
    children: [{
        path: "MenuRole",
        menu_url: "/SystemTool/MenuRole",
        name: "MenuRole",
        meta: {
          title: "菜单管理",
          icon: "icon-caidanquanxian",
          code: "system_menu",
        },
        component: () => import("@/views/SystemTool/MenuRole"),
      },
      {
        path: "DepartManagement",
        menu_url: "/SystemTool/DepartManagement",
        name: "DepartManagement",
        meta: {
          title: "部门管理",
          icon: "icon-bumenguanli"
        },
        component: () => import("@/views/SystemTool/DepartManagement"),
      },
      {
        path: "RoleManagement",
        menu_url: "/SystemTool/RoleManagement",
        name: "RoleManagement",
        meta: {
          title: "角色管理",
          icon: "icon-jiaoseguanli",
          code: "system_role",
        },
        component: () => import("@/views/SystemTool/RoleManagement"),
      },
      {
        path: "UserManagement",
        menu_url: "/SystemTool/UserManagement",
        name: "UserManagement",
        meta: {
          title: "人员管理",
          icon: "icon-a-24pxzhixiaokehu",
          code: "system_user",
        },
        component: () => import("@/views/SystemTool/UserManagement"),
      },
      {
        path: "/SystemTool/OperationRecord",
        menu_url: "/SystemTool/OperationRecord",
        name: "OperationRecord",
        meta: {
          title: "功能使用日志",
          icon: "icon-prefixIcon",
          code: "system_operationRecord",
        },
        component: () => import("@/views/SystemTool/OperationRecord"),
      },
    ],
  },
  {
    path: "Wechat",
    menu_url: "/Wechat",
    name: "Wechat",
    meta: {
      title: "岚江公众号",
      icon: "",
      code: "wechat"
    },
    component: () => import("@/views/Wechat/"),
    redirect: "/Wechat/TrainingVideo",
    children: [{
      path: "TrainingVideo",
      menu_url: "/Wechat/TrainingVideo",
      name: "TrainingVideo",
      meta: {
        title: "视频培训",
        icon: "icon-xian",
        code: "training",
      },
      component: () => import("@/views/Wechat/TrainingVideo"),
    }, ],
  },
  {
    path: "ProductionMa",
    menu_url: "/ProductionMa",
    name: "ProductionMa",
    meta: {
      title: "岚江生产管理",
      icon: "",
      code: "production"
    },
    component: () => import("@/views/ProductionMa/"),
    redirect: "/ProductionMa/CarProtect",
    children: [{
        path: "CarInfoEnter",
        menu_url: "/ProductionMa/CarInfoEnter",
        name: "WebsiteIndexPage",
        meta: {
          title: "智能补给平台管理",
          icon: "icon-huise",
          code: "website_index_page",
        },
        component: () => import("@/views/ProductionMa/CarInfoEnter"),
        redirect: "/WebsiteMa/Index/Banner",
        children: [{
            path: "NannyCar",
            menu_url: "/ProductionMa/CarInfoEnter/NannyCar",
            name: "NannyCar",
            meta: {
              title: "智能补给平台信息录入",
              icon: "",
              code: "website_index_page_banner",
            },
            component: () =>
              import("@/views/ProductionMa/CarInfoEnter/NannyCar"),
          },
          // {
          //   path: "DriverlessCar",
          //   menu_url: "/ProductionMa/CarInfoEnter/DriverlessCar",
          //   name: "DriverlessCar",
          //   meta: {
          //     title: "果园机器人录入",
          //     icon: "",
          //     code: "website_index_page_video",
          //   },
          //   component: () =>
          //     import("@/views/ProductionMa/CarInfoEnter/DriverlessCar"),
          // },
          {
            path: "MedicineMa",
            menu_url: "/ProductionMa/CarInfoEnter/MedicineMa",
            name: "MedicineMa",
            meta: {
              title: "药水种类管理",
              icon: "",
              code: "production_yaoshui",
            },
            component: () =>
              import("@/views/ProductionMa/CarInfoEnter/MedicineMa"),
          },
        ],
      },
      {
        path: "MultionRobot",
        menu_url: "/ProductionMa/MultionRobot",
        name: "WebsiteIndexPage",
        meta: {
          title: "果园多功能机器人",
          icon: "icon-a-24pxnongji1",
        },
        component: () => import("@/views/ProductionMa/MultionRobot"),
        children: [{
            path: "DrivelessCar",
            menu_url: "/ProductionMa/MultionRobot/DrivelessCar",
            name: "DrivelessCar",
            meta: {
              title: "果园多功能机器人信息录入",
            },
            component: () =>
              import("@/views/ProductionMa/MultionRobot/DrivelessCar"),
          },
          {
            path: "SharePath",
            menu_url: "/ProductionMa/MultionRobot/SharePath",
            name: "SharePath",
            meta: {
              title: "共享路径管理",
            },
            component: () =>
              import("@/views/ProductionMa/MultionRobot/SharePath"),
          },
        ],
      },
      {
        path: "QrcodeMa",
        menu_url: "/ProductionMa/QrcodeMa",
        name: "QrcodeMa",
        meta: {
          title: "车辆铭牌管理",
          icon: "icon-a-24pxmingpai1",
          code: "production_qrcode",
        },
        component: () => import("@/views/ProductionMa/QrcodeMa/"),
        redirect: "/ProductionMa/QrcodeMa/QrCreate",
        children: [{
          path: "QrCreate",
          menu_url: "/ProductionMa/QrcodeMa/QrCreate",
          name: "QrCreate",
          meta: {
            title: "二维码生成",
            icon: "",
            code: "production_QrCreate",
          },
          component: () => import("@/views/ProductionMa/QrcodeMa/QrCreate"),
        }, ],
      },
      {
        path: "WorkMonitor",
        menu_url: "/ProductionMa/WorkMonitor",
        name: "WorkMonitor",
        meta: {
          title: "作业数据查看",
          icon: "icon-zuoyeshuju",
          code: "work"
        },
        component: () => import("@/views/ProductionMa/Work"),
      },
      {
        path: "WorkMonitor/detail",
        menu_url: "/ProductionMa/WorkMonitor/detail",
        name: "WorkMonitorDetail",
        meta: {
          title: "作业监测详情",
          icon: "",
          code: "work_detail",
          hideMenu: true,
        },
        component: () => import("@/views/ProductionMa/Work/detail"),
      },
      {
        path: "WorkMonitor/WorkAnalysis",
        menu_url: "/ProductionMa/WorkMonitor/WorkAnalysis",
        name: "WorkAnalysis",
        meta: {
          title: "轨迹查询",
          icon: "",
          code: "work_analysis",
          hideMenu: true,
        },
        component: () => import("@/views/ProductionMa/Work/WorkAnalysis"),
      },
      {
        path: "WorkMonitor/superaberration",
        menu_url: "/ProductionMa/WorkMonitor/superaberration",
        name: "superaberration",
        meta: {
          title: "轨迹查询",
          icon: "",
          code: "work_analysis",
          hideMenu: true,
        },
        component: () => import("@/views/ProductionMa/Work/superaberration"),
      },
      {
        path: "SalesMa",
        menu_url: "/ProductionMa/SalesMa",
        name: "SalesMa",
        meta: {
          title: "车辆销售管理",
          icon: "icon-xian1",
          code: "SalesMa",
        },
        component: () => import("@/views/ProductionMa/SalesMa"),
        // children: [
        //   {
        //     path: "Unsold",
        //     menu_url: "/ProductionMa/SalesMa/Unsold",
        //     name: "Unsold",
        //     meta: {
        //       title: "未销车辆管理",
        //       icon: "",
        //       hideMenu: true,
        //       code: "SalesMa_unsold",
        //     },
        //     component: () => import("@/views/ProductionMa/SalesMa/Unsold"),
        //   },
        //   {
        //     path: "Straight",
        //     menu_url: "/ProductionMa/SalesMa/Straight",
        //     name: "Straight",
        //     meta: {
        //       title: "代销车辆管理",
        //       icon: "",
        //       code: "SalesMa_straight",
        //     },
        //     component: () => import("@/views/ProductionMa/SalesMa/Straight"),
        //   },
        //   {
        //     path: "Generation",
        //     menu_url: "/ProductionMa/SalesMa/Generation",
        //     name: "Generation",
        //     meta: {
        //       title: "直销车辆管理",
        //       icon: "",
        //       code: "SalesMa_generation",
        //     },
        //     component: () => import("@/views/ProductionMa/SalesMa/Generation"),
        //   },
        // ],
      },
      {
        path: "BindCar",
        menu_url: "/ProductionMa/BindCar",
        name: "BindCar",
        meta: {
          title: "车辆作业绑定",
          icon: "icon-a-24pxbangding1",
          code: "nanny_car",
        },
        component: () => import("@/views/ProductionMa/BindCar"),
      },
      {
        path: "/ProductionMa/QrcodeMa/NumberArg",
        menu_url: "/ProductionMa/QrcodeMa/NumberArg",
        name: "NumberArg",
        meta: {
          title: "产品代号参数管理",
          icon: "icon-chanpincanshu",
          code: "production_qrcodeMa",
        },
        component: () => import("@/views/ProductionMa/QrcodeMa/NumberArg"),
      },
      {
        path: "NavigateLog",
        menu_url: "/ProductionMa/NavigateLog",
        name: "NavigateLog",
        meta: {
          title: "导航日志管理",
          icon: "icon-a-24pxrizhi1"
        },
        component: () => import("@/views/ProductionMa/NavigateLog"),
      },
      {
        path: "Upgrade",
        menu_url: "/ProductionMa/Upgrade",
        name: "Upgrade",
        meta: {
          title: "ota升级管理",
          icon: "icon-a-24pxOTA1"
        },
        component: () => import("@/views/ProductionMa/Upgrade"),
      },

      // 车辆管理
      {
        path: "VehicleManagement",
        menu_url: "/ProductionMa/VehicleManagement",
        name: "VehicleManagement",
        meta: {
          title: "车辆管理",
          icon: "icon-cheliangguanli",
          code: "vehicle_management",
        },
        component: () => import("@/views/ProductionMa/VehicleManagement"),
        redirect: "/Payment",
        children: [{
            path: "Payment",
            menu_url: "/ProductionMa/VehicleManagement/Payment",
            name: "Payment",
            meta: {
              title: "缴费续费",
              icon: "",
              code: "payment_renewal",
            },
            component: () =>
              import("@/views/ProductionMa/VehicleManagement/Payment"),
          },
          {
            path: "AfterSales",
            menu_url: "/ProductionMa/VehicleManagement/AfterSales",
            name: "AfterSales",
            meta: {
              title: "售后配件更换",
              icon: "",
              code: "aftermarket_replacement",
            },
            component: () =>
              import("@/views/ProductionMa/VehicleManagement/AfterSales"),
          },
          {
            path: "RemoteLock",
            menu_url: "/ProductionMa/VehicleManagement/RemoteLock",
            name: "RemoteLock",
            meta: {
              title: "远程锁定",
              icon: "",
              code: "aftermarket_replacement",
            },
            component: () =>
              import("@/views/ProductionMa/VehicleManagement/RemoteLock"),
          },
          {
            path: "EquipmentRepair",
            menu_url: "/ProductionMa/VehicleManagement/EquipmentRepair",
            name: "EquipmentRepair",
            meta: {
              title: "设备维修",
              icon: "",
              code: "EquipmentRepair",
            },
            component: () =>
              import("@/views/ProductionMa/VehicleManagement/EquipmentRepair"),
          },
          {
            path: "ExpireManage",
            menu_url: "/ProductionMa/VehicleManagement/ExpireManage",
            name: "ExpireManage",
            meta: {
              title: "到期管理",
              icon: "",
              code: "ExpireManage",
            },
            component: () =>
              import("@/views/ProductionMa/VehicleManagement/ExpireManage"),
          },
        ],
      },
      {
        path: "/ProductionMa/PathMa",
        menu_url: "/ProductionMa/PathMa",
        name: "PathMa",
        meta: {
          title: "规划路径管理",
          icon: "icon-lujing",
          code: "production_pathMa",
        },
        component: () => import("@/views/ProductionMa/PathMa"),
      },
      {
        path: "OfflineCache",
        menu_url: "/ProductionMa/OfflineCache",
        name: "OfflineCache",
        meta: {
          title: "离线缓存数据",
          icon: "icon-zuoyeshuju",
          code: "work"
        },
        component: () => import("@/views/ProductionMa/OfflineCache"),
      },
      {
        path: "/ProductionMa/RtkParameterMa",
        menu_url: "/ProductionMa/RtkParameterMa",
        name: "RtkParameterMa",
        meta: {
          title: "RTK参数管理",
          icon: "icon-chanpincanshu",
          code: "production_rtkParameterMa",
        },
        component: () => import("@/views/ProductionMa/RtkParameterMa"),
      },
      {
        path: "/ProductionMa/FaultDataManage",
        menu_url: "/ProductionMa/FaultDataManage",
        name: "FaultDataManage",
        meta: {
          title: "故障数据管理",
          icon: "icon-chanpincanshu",
          code: "production_rtkParameterMa",
        },
        component: () => import("@/views/ProductionMa/FaultDataManage"),
      },
      {
        path: "/ProductionMa/PathList",
        menu_url: "/ProductionMa/PathList",
        name: "PathMa",
        meta: {
          title: "规划路径列表",
          icon: "icon-lujing",
          code: "production_pathList",
          hideMenu: true,
        },
        component: () => import("@/views/ProductionMa/PathMa/PathList.vue"),
      },
      {
        path: "/ProductionMa/PathEdit",
        menu_url: "/ProductionMa/PathEdit",
        name: "PathMa",
        meta: {
          title: "规划路径编辑",
          icon: "icon-lujing",
          code: "production_pathEdit",
          hideMenu: true,
        },
        component: () => import("@/views/ProductionMa/PathMa/PathEdit.vue"),
      },
			{
			  path: "/ProductionMa/PathEditDiscrete",
			  menu_url: "/ProductionMa/PathEditDiscrete",
			  name: "PathMa",
			  meta: {
			    title: "规划路径编辑",
			    icon: "icon-lujing",
			    code: "production_pathEdit",
			    hideMenu: true,
			  },
			  component: () => import("@/views/ProductionMa/PathMa/PathEditDiscrete.vue"),
			},
    ],
  },
  {
    path: "WebsiteMa",
    menu_url: "/WebsiteMa",
    name: "WebsiteMa",
    meta: {
      title: "岚江官网",
      icon: "",
      code: "WebsiteMa",
    },
    component: () => import("@/views/WebsiteMa/"),
    redirect: "/WebsiteMa/IndexPage",
    children: [{
        path: "/WebsiteMa/IndexPage",
        menu_url: "/WebsiteMa/IndexPage",
        name: "WebsiteIndexPage",
        meta: {
          title: "首页",
          icon: "icon-shouye1",
          code: "website_index_page",
        },
        component: () => import("@/views/WebsiteMa/IndexPage"),
        redirect: "/WebsiteMa/IndexPage/Banner",
        children: [{
            path: "/WebsiteMa/IndexPage/Banner",
            menu_url: "/WebsiteMa/IndexPage/Banner",
            name: "WebsiteIndexPageBanner",
            meta: {
              title: "轮播图管理",
              icon: "",
              code: "website_index_page_banner",
            },
            component: () => import("@/views/WebsiteMa/IndexPage/CarouselMa"),
          },
          {
            path: "/WebsiteMa/IndexPage/VideoMa",
            menu_url: "/WebsiteMa/IndexPage/VideoMa",
            name: "WebsiteIndexPageVideoMa",
            meta: {
              title: "宣传视频管理",
              icon: "",
              code: "website_index_page_video",
            },
            component: () => import("@/views/WebsiteMa/IndexPage/VideoMa"),
          },
        ],
      },
      {
        path: "ProductCenter",
        menu_url: "/WebsiteMa/ProductCenter",
        name: "WebsiteProductCenter",
        meta: {
          title: "产品中心管理",
          icon: "icon-chanpinzhongxinguanli",
          code: "website_product_center",
        },
        component: () => import("@/views/WebsiteMa/ProductCenter"),
      },
      {
        path: "SolveProcedule",
        menu_url: "/WebsiteMa/SolveProcedule",
        name: "WebsiteSolveProcedule",
        meta: {
          title: "解决方案管理",
          icon: "icon-jiejuefangan",
          code: "website_solve_procedule",
        },
        component: () => import("@/views/WebsiteMa/SolveProcedule"),
      },
      {
        path: "UserCase",
        menu_url: "/WebsiteMa/UserCase",
        name: "WebsiteUserCase",
        meta: {
          title: "用户案例管理",
          icon: "icon-yonghuanli",
          code: "website_user_case",
        },
        component: () => import("@/views/WebsiteMa/UserCase"),
      },
      {
        path: "NewsCenter",
        menu_url: "/WebsiteMa/NewsCenter",
        name: "WebsiteNewsCenter",
        meta: {
          title: "新闻中心管理",
          icon: "icon-xinwenzhongxin",
          code: "website_user_center",
        },
        component: () => import("@/views/WebsiteMa/NewsCenter"),
      },
    ],
  },
  /* {
    path: "Agent",
    menu_url: "/Agent",
    name: "Agent",
    meta: { title: "代理商客户", icon: "icon-unit", code: "agency" },
    component: () => import("@/views/Agent/"),
    redirect: "/Agent/AgentInfo",
    children: [
      {
        path: "AgentInfo",
        menu_url: "/Agent/AgentInfo",
        name: "AgentInfo",
        meta: { title: "代理商信息管理", icon: "", code: "agency_Info" },
        component: () => import("@/views/Agent/AgentInfo"),
      },
      {
        path: "CustomInfo",
        menu_url: "/Agent/CustomInfo",
        name: "CustomInfo",
        meta: { title: "代理商客户信息管理", icon: "", code: "custom_Info" },
        component: () => import("@/views/Agent/CustomInfo"),
      },
    ],
  }, */
  {
    path: "Manage",
    menu_url: "/Manage",
    name: "Manage",
    meta: {
      title: "岚江数智农业",
      icon: ""
    },
    component: () => import("@/views/Manage/"),
    redirect: "/Manage/DataMaintenance",
    children: [{
        path: "/Manage/Organization",
        menu_url: "/Manage/Organization",
        name: "Organization",
        meta: {
          title: "组织信息管理",
          icon: "icon-zuzhiicon"
        },
        component: () => import("@/views/LanjiangAccountInfo"),
        children: [{
            path: "/Manage/Organization/Company",
            menu_url: "/Manage/Organization/Company",
            name: "Organization",
            meta: {
              title: "企业信息管理",
              icon: ""
            },
            component: () => import("@/views/LanjiangAccountInfo/UserInfo"),
          },
          {
            path: "/Manage/Organization/User",
            menu_url: "/Manage/Organization/User",
            name: "Organization",
            meta: {
              title: "人员信息管理",
              icon: ""
            },
            component: () => import("@/views/LanjiangAccountInfo/CompanyUser"),
          },
          {
            path: "/Manage/Organization/companyBase",
            menu_url: "/Manage/Organization/companyBase",
            name: "company_base",
            meta: {
              title: "基地信息管理",
              icon: "",
              code: "Manage_organization_base",
            },
            component: () =>
              import("@/views/LanjiangCompany/UnitMa/CompanyBase"),
          },
        ],
      },
      {
        path: "DataMaintenance",
        menu_url: "/Manage/DataMaintenance",
        name: "DataMaintenance",
        meta: {
          title: "基础数据维护",
          icon: "icon-jichushuju"
        },
        component: () => import("@/views/Manage/DataMaintenance"),
      },
    ],
  },
  {
    path: "AccountAllocation",
    menu_url: "/AccountAllocation",
    name: "AccountAllocation",
    meta: {
      title: "高精度账号动态分配管理",
      icon: "",
      code: "allocation",
    },
    component: () => import("@/views/AccountAllocation/"),
    redirect: "/AccountAllocation/Account",
    children: [{
        path: "Account",
        menu_url: "/AccountAllocation/Account",
        name: "Account",
        meta: {
          title: "账号管理",
          icon: "icon-zhanghao",
          code: "account"
        },
        component: () => import("@/views/AccountAllocation/Account"),
      },
      {
        path: "Monitor",
        menu_url: "/AccountAllocation/Monitor",
        name: "Monitor",
        meta: {
          title: "监测管理",
          icon: "icon-jiance",
          code: "monitor"
        },
        component: () => import("@/views/AccountAllocation/Monitor"),
      },
      {
        path: "Information",
        menu_url: "/AccountAllocation/Information",
        name: "Information",
        meta: {
          title: "预警消息",
          icon: "icon-notification",
          code: "information",
        },
        component: () => import("@/views/AccountAllocation/Information"),
      },
      {
        path: "SystemSettings",
        menu_url: "/AccountAllocation/SystemSettings",
        name: "SystemSettings",
        meta: {
          title: "系统设置",
          icon: "icon-server",
          code: "systemSettings",
        },
        component: () => import("@/views/AccountAllocation/SystemSettings"),
      },
      {
        path: "AccountSeting",
        menu_url: "/AccountAllocation/AccountSeting",
        name: "AccountSeting",
        meta: {
          title: "账号code设置",
          icon: "icon-zhanghao",
          code: "account"
        },
        component: () => import("@/views/AccountAllocation/AccountSeting"),
      },
    ],
  },
  {
    path: "DataOverview",
    menu_url: "/DataOverview",
    name: "DataOverview",
    meta: {
      title: "数据驾驶舱",
      icon: "",
      code: "DataOverview"
    },
    component: () => import("@/views/DataOverview/"),
    redirect: "/DataOverview/DataReport",
    children: [{
      path: "DataReport",
      menu_url: "/DataOverview/DataReport",
      name: "DataReport",
      meta: {
        title: "综合数据分析",
        icon: "icon-xian",
        code: "DataReport",
      },
      component: () => import("@/views/DataOverview/DataReport"),
    }, ],
  },

  {
    path: "CarMonitorScreen",
    menu_url: "/CarMonitorScreen",
    name: "CarMonitorScreen",
    meta: {
      title: "车辆监测大屏",
      icon: "",
      code: "CarMonitorScreen"
    },
    component: () => import("@/views/CarMonitorScreen/"),
    redirect: "/CarMonitorScreen",
    children: [{
      path: "CarMonitorScreen",
      menu_url: "/CarMonitorScreen",
      name: "CarMonitorScreen",
      meta: {
        title: "岚江车辆监测",
        icon: "icon-xian",
        code: "MonitorScreen",
      },
      component: () => import("@/views/CarMonitorScreen"),
    }, ],
  },
];
export default dynamicRoutes;