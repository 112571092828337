import login from "@/api/login";
import CarProtectMa from "@/api/ProductionMa/CarProtectMa";
import UserInfo from "@/api/UnitMa/UserInfo";
import MedicineMa from "@/api/ProductionMa/MedicineMa";
import CarDriverless from "@/api/ProductionMa/CarDriverless";
import VehicleInfo from "@/api/ProductionMa/VehicleInfo";
import SharedPath from "@/api/ProductionMa/SharedPath";
import MenuRole from "@/api/SystemTool/MenuRole";
import RoleManagement from "@/api/SystemTool/RoleManagement";
import DepartManagement from "@/api/SystemTool/DepartManagement";
import UserManagement from "@/api/SystemTool/UserManagement";
import OperationRecord from "@/api/SystemTool/OperationRecord";
import SolveProcedule from "@/api/WebsiteMa/SolveProcedule";
import UserCase from "@/api/WebsiteMa/UserCase";
import NewsCenter from "@/api/WebsiteMa/NewsCenter";
import Qrcode from "@/api/Qrcode/";
import SalesMa from "@/api/ProductionMa/SalesMa/";
import agent from "@/api/agent/";
import BindCar from "@/api/ProductionMa/BindCar";
import VehicleManagement from "@/api/ProductionMa/VehicleManagement";
import EquipmentRepair from "@/api/ProductionMa/EquipmentRepair/";
import RtkMa from "@/api/ProductionMa/RtkMa/";


export default {
  login,
  CarProtectMa,
  UserInfo,
  MedicineMa,
  CarDriverless,
  VehicleInfo,
  MenuRole,
  RoleManagement,
  DepartManagement,
  UserManagement,
	OperationRecord,
  SharedPath,
  SolveProcedule,
  UserCase,
  NewsCenter,
  Qrcode,
  SalesMa,
  agent,
  BindCar,
  VehicleManagement,
  EquipmentRepair,
  RtkMa
  // ……
};
