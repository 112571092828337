import request from "@utils/request";
import axios from "axios";

// 查询所有系统配置
export default {
  companyList(data) {
    return request({
      url: "/company/list",
      method: "get",
      params: data,
    });
  },
  //单个搜索
  companyListSearch(data) {
    return request({
      url: "/company/searchList",
      method: "get",
      params: data,
    });
  },
  companyAdd(data) {
    return request({
      url: "/company/add",
      method: "post",
      data,
    });
  },
  companyEdit(data) {
    return request({
      url: "/company/edit",
      method: "put",
      data,
    });
  },
  companyDel(data) {
    return request({
      url: "/company/del",
      method: "delete",
      data,
    });
  },
  //{{url}}/companyUser/all?page_index=1&page_size=10
  companyUser(data) {
    return request({
      url: "/companyUser/all",
      method: "get",
      params: data,
    });
  },
  companyInfo(data) {
    return request({
      url: "/company/info",
      method: "get",
      params: data,
    });
  },
  companyUserAdd(data) {
    return request({
      url: "/companyUser/add",
      method: "post",
      data,
    });
  },
  companyUserEdit(data) {
    return request({
      url: "/companyUser/edit",
      method: "put",
      data,
    });
  },
  companyUserDel(data) {
    return request({
      url: "/companyUser/del",
      method: "delete",
      data,
    });
  },
  getCity() {
    return axios({
      url:
        process.env.NODE_ENV !== "production"
          ? "/city_all.json"
          : `${process.env.VUE_APP_SERVE_CITY}city_all.json`,
      method: "get",
    }).then((res) => {
      return res.data;
    });
  },
  //新增农场
  farmAdd(data) {
    return request({
      url: "/farm/add",
      method: "post",
      data,
    });
  },
  //获取农场列表
  farmList(data) {
    return request({
      url: "/farm/list",
      method: "get",
      params: data,
    });
  },
  //修改农场
  farmModify(data) {
    return request({
      url: "/farm/edit",
      method: "put",
      data,
    });
  },
  //删除农场
  farmDel(data) {
    return request({
      url: "/farm/del",
      method: "delete",
      data,
    });
  },
  //农场详情
  farmDetail(data) {
    return request({
      url: "/farm/info",
      method: "get",
      params: data,
    });
  },
  //获取路径列表
  pointList(data) {
    return request({
      url: "/path/point/list",
      method: "get",
      params: data,
    });
  },
  //获取路径详情
  pointDetail(data) {
    return request({
      url: "/path/point/info",
      method: "get",
      params: data,
    });
  },
  supplyPointList(params) {
    return request({
      url: "/supply/point/list",
      method: "get",
      params,
    });
  },
  supplyPointAdd(data) {
    return request({
      url: "/supply/point/add",
      method: "post",
      data,
    });
  },
  supplyPointDis(data) {
    return request({
      url: "/path/point/edit/supply_point_dis",
      method: "put",
      data,
    });
  },
  supplyPointTime(data) {
    return request({
      url: "/path/point/edit/supply_point_time",
      method: "put",
      data,
    });
  },
  //用户管理新增下拉列表
  userBandSelect(params) {
    return request({
      url: "/farm/species/system/list",
      method: "get",
      params,
    });
  },

  //编辑路径
  pointEdit(data) {
    return request({
      url: "/path/point/edit/path",
      method: "put",
      data,
    });
  },
  csvUpload(data) {
    return request({
      url: "/path/point/create",
      method: "post",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  //导出企业信息
  //编辑路径
  exportCompany(params) {
    return request({
      url: "/company/export/list",
      method: "get",
      params: params,
      responseType: "blob",
    });
  },
};
