<template>
  <div>
    <a-menu
      :inlineIndent="inlineIndent"
      :selectedKeys="defaultSelectedKeys"
      :openKeys="openKeys"
      :mode="mode"
      :collapsed="collapsed"
      @openChange="onOpenChange"
      @click="menuClick"
    >
      <!-- sidebarMenu -->
      <template v-for="item in sidebarSecondMenuFormat">
        <template v-if="!item.meta.hideMenu">
          <a-menu-item v-if="!item.children" :key="item.menu_url">
            <i :class="['iconfont m-r-10', item.icon]" />
            <span class="menu_name">{{ item.title }}</span>
          </a-menu-item>

          <sub-menu v-else :key="item.menu_url" :menu-info="item" />
        </template>
      </template>
    </a-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SubMenu from "@components/DynamicSider";

export default {
  components: {
    SubMenu,
  },
  data() {
    return {
      // 菜单缩进
      inlineIndent: 12,
      // 默认不折叠
      collapsed: false,
      // 全部父节点
      rootSubmenuKeys: [],
      openKeys: [], //默认展开的节点
      defaultOpenKeys: [],
      // 选中的子菜单项
      defaultSelectedKeys: [this.$route.path],
      sidebarSecondMenuFormat: [],
    };
  },
  computed: {
    mode: {
      get() {
        return this.sidebar.opened ? "inline" : "vertical";
      },
    },

    ...mapState({
      sidebarMenu: (state) => state.permissions.sidebarMenu,
      sidebarSecondMenu: (state) => state.permissions.sidebarSecondMenu,
      sidebar: (state) => state.app.sidebar,
    }),
  },
  watch: {
    mode(nv, ov) {
      if (nv !== ov) {
        setTimeout(() => {
          this.changePosition();
        }, 0);
      }
    },
    // sidebarSecondMenu: {
    //   handler(nv, ov) {
    //     if (nv.menu_url !== ov.menu_url) {
    //       console.log("pp----");

    //     }
    //   },
    //   deep: true,
    // },
    $route(nv, ov) {
      if (nv !== ov) {
        this.defaultSelectedKeys = [nv.path];
        this.resetSidebarSecondMenuFormat();
      }
    },
  },
  /* updated() {
    this.$nextTick(() => {
      this.changePosition();
    });
  }, */

  methods: {
    resetSidebarSecondMenuFormat() {
      this.sidebarSecondMenuFormat =
        this.sidebarSecondMenu.children &&
        this.sidebarSecondMenu.children.length > 0
          ? this.sidebarSecondMenu.children
          : [];

      this.setOpenKeys();
    },
    //设置内层菜单位置
    changePosition() {
      const dom = document.querySelector(".ant-menu-submenu-popup");
      if (dom) {
        dom.style.left = this.sidebar.opened ? "200px" : "56px";
      }
    },
    //  控制只打开一个
    onOpenChange(openKeys) {
      // 将当前打开的父级菜单存入缓存中
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );

      // this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
      window.localStorage.setItem(
        "systemOpenKeys",
        JSON.stringify(this.openKeys)
      );
    },
    // 点击菜单，路由跳转,注意的是当点击MenuItem才会触发此函数
    // eslint-disable-next-line no-unused-vars
    menuClick({ item, key, keyPath }) {
      // 获取到当前的key,并且跳转
      this.$router.push({
        path: key,
      });
    },
    setDefaultMenu() {
      this.rootSubmenuKeys = Array.isArray(this.sidebarSecondMenuFormat)
        ? this.sidebarSecondMenuFormat.map((item) => item.menu_url)
        : [];
    },
    setOpenKeys() {
      this.setDefaultMenu();
      const openKeys = window.localStorage.getItem("systemOpenKeys");
      if (openKeys) {
        // 存在即赋值
        this.openKeys = JSON.parse(openKeys);
      } else {
        const path = this.$route.path;
        const index = this.rootSubmenuKeys.findIndex(
          (item) => path.indexOf(item) > -1
        );
        this.openKeys = [
          this.rootSubmenuKeys[index] ? this.rootSubmenuKeys[index] : "",
        ];
      }
    },
  },
  created() {
    // 将从缓存中取出openKeys
    // this.getSystemPermission();
    // this.setOpenKeys();
    this.resetSidebarSecondMenuFormat();
  },
};
</script>
<style lang="less" scoped></style>
