import { render, staticRenderFns } from "./HeadCom.vue?vue&type=template&id=542a1886&scoped=true&"
import script from "./HeadCom.vue?vue&type=script&lang=js&"
export * from "./HeadCom.vue?vue&type=script&lang=js&"
import style0 from "./HeadCom.vue?vue&type=style&index=0&id=542a1886&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "542a1886",
  null
  
)

export default component.exports