/**
 * 可以录入静态字典
 * 需要用到的地方再调用字典接口，同步本地的store，保持最新
 */

import dic from "@/api/dictionary";
const state = {
  // 官网管理//首页管理//轮播图管理
  6: {},
  // 投入品类别
  7: {},
  // 灾害类别
  8: {},
  // 气象灾害
  9: {},
  //导航类型
  13: {
    5601: "岚江导航机器",
    5602: "联适导航机器",
    5603: "无导航",
  },
  // 药物类型
  24: {},
  // 销售类型
  40: {
    7520: "直销",
    7521: "代销",
  },
  //状态搜索
  43: {},
  58: {},
	// 产品类型
	30:{
		6901: "果园无人机器人",
		6902: "保姆车",
		6903: "巡管车",
	}
};

const mutations = {
  SET_DICTIONARY(state, value) {
    state[value.type] = value.val;
  },
};

const actions = {
  // 获取字典
  getDictionary({ commit }, params) {
    return new Promise((resolve) => {
      dic.dictionary(params).then((res) => {
        if (res.err_code === 200) {
          const data = res.err_data;
          let obj = {};
          for (let i = 0; i < data.length; i++) {
            obj[data[i].dictionary_id] = data[i].dictionary_name;
          }
          commit("SET_DICTIONARY", {
            type: params,
            val: obj,
          });
          resolve(data);
        }
      });
    });
  },
};

export default {
  state,
  mutations,
  actions,
};
