import request from "@utils/request";

// 查询所有系统配置
export default {
  list(data) {
    return request({
      url: "/agvcar/summary/list",
      method: "get",
      params: data,
    });
  },
  alarmList(data) {
    return request({
      url: "/device/alarm/list",
      method: "get",
      params: data,
    });
  },
  delSummary(data) {
    return request({
      url: "/agvcar/summary/del",
      method: "DELETE",
      data,
    });
  },
};
