const MonitorScreenMileageChart = (data) => {
  const defaultConfig = {
    legend: {
      show: true,
      top: "5%",
      right: "3%",
      textStyle: {
        color: "#fff",
        fontSize: 12,
      },
      icon: "square",
      itemWidth: 10,
      itemHeight: 10,
      // formatter: (name) => {
      //   return `${name}\t${obj[name]}`;
      // },
    },
    grid: {
      left: 0,
      right: 0,
      top: '20%',
      bottom: 0,
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
      backgroundColor: "rgba(1,173,249,0.2)",
      borderColor: "#47FFFF",
      borderWidth: 1,
      textStyle: {
        color: "#fff",
        fontSize: 14,
        fontWeight: 500,
        align: "left",
      },
      axisPointer: {
        type: "shadow",
        shadowStyle: {
          shadowBlur: 1,
        },
      },
    },
    xAxis: {
      type: "category",
      data: data.xData,
      axisLine: {
        lineStyle: {
          color: "#6D7A80FF",
        },
      },
      axisLabel: {
        color: "rgba(255,255,255,0.7)",
        fontSize: 12,
        interval: 0,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      min: 0,
      name: "单位:km",
      nameTextStyle: {
        color: 'rgba(255,255,255,0.5)',
        padding: [2, 2, 2, 2],
      },
      splitLine: {
        lineStyle: {
          color: "#6D7A80",
          type: "line",
        },
      },
      axisLabel: {
        fontSize: 12,
        color: 'rgba(255, 255, 255, 0.6)',
      },
    },
    series: [{
        name: "作业里程",
        type: "bar",
        label: {
          show: false,
        },
        barWidth: "16",
        barGap: false,
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 1,
              color: 'rgba(1,173,249,0)' // 100% 处的颜色
            }, {
              offset: 0,
              color: '#01ADF9' // 0% 处的颜色
            }],
          },
        },
        data: data.yData0,
      },
      {
        name: "行驶里程",
        type: "bar",
        label: {
          show: false,
        },
        barWidth: "16",
        barGap: false,
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 1,
              color: 'rgba(255,205,62,0)' // 0% 处的颜色
            }, {
              offset: 0,
              color: '#FFCD3E' // 99% 处的颜色
            }],
          },
        },
        data: data.yData1,
      },
    ],
  };

  const opt = Object.assign({}, defaultConfig);
  return opt;
};

export default {
  MonitorScreenMileageChart,
};