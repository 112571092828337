import request from "@utils/request";

export default {
  // 获取字典
  dictionary(type) {
    return request({
      url: "/dictionary/get/" + type,
      method: "get",
    });
  },
};
