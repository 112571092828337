import request from "@utils/request";

const urlParams = {
  policyRoleList: "/policy/role/list", // 获取所有角色
  policyRoleGet: "/policy/role/get", // 获取该角色有哪些权限
  policyRoleSet: "/policy/role/set", // 设置权限
};

// 查询所有系统配置
export default {
  policyRoleList() {
    return request({
      url: urlParams.policyRoleList,
      method: "get",
    });
  },
  policyRoleGet(data) {
    return request({
      url: urlParams.policyRoleGet,
      method: "get",
      params: data,
    });
  },
  policyRoleSet(data) {
    return request({
      url: urlParams.policyRoleSet,
      method: "post",
      data,
    });
  },
};
