<template>
  <a-layout :class="['layout', classObj]">
    <a-layout-header class="layout-header"><topbar /></a-layout-header>
    <a-layout class="layout-content">
      <a-layout-sider
        class="full-height p-t-10 sidebar-container"
        :width="getWd"
      >
        <div class="sider-bar">
          <sider-bar />
        </div>
        <!--  <hamburger
          v-if="!sidebar.hide"
          :is-active="sidebar.opened"
          class="hamburger-container"
          :class="{ 'fixed-wd': !sidebar.opened }"
          @toggleClick="toggleSideBar"
        /> -->
        <div
          v-if="!sidebar.hide"
          class="hamburger-container"
          :class="{ 'fixed-wd': !sidebar.opened }"
          @click="toggleSideBar"
        >
          <i
            class="iconfont f-s-20"
            :class="{
              'icon-zhankai2': !sidebar.opened,
              'icon-suoxiao': sidebar.opened,
            }"
          ></i>
        </div>
      </a-layout-sider>
      <a-layout-content class="layout-wrap main-container full-height">
        <app-main />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import SiderBar from "./components/SiderBar.vue";
import AppMain from "./components/AppMain.vue";
// import Topbar from "@/components/Topbar.vue";
import Topbar from "./components/Topbar.vue";
// import Hamburger from "@/components/Hamburger";
export default {
  name: "Layout",
  components: {
    SiderBar,
    AppMain,
    Topbar,
    // Hamburger,
  },
  data() {
    return {};
  },

  props: {},
  watch: {},
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile",
      };
    },
    getWd() {
      return this.sidebar.opened ? 200 : 72;
    },
  },
  created() {},
  mounted() {
    // this.getWd();
  },
  methods: {
    //切换sideBar
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/styles/mixin.less";
.layout {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  &-header {
    height: 64px;
    padding: 0 !important;
  }
  &-content {
    height: calc(100vh - 64px);
  }

  &-footer {
    height: 64px;
  }
  &-wrap {
    overflow: auto;
    #scrollBar();
  }
}
.sider-bar {
  height: calc(100vh - 160px);
  overflow-y: overlay;
  overflow-x: hidden;
  &::-webkit-scrollbar-track-piece {
    background: #d3dce6;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #99a9bf;
    border-radius: 20px;
  }
}
.hamburger-container {
  position: fixed;
  left: 170px;
  bottom: 20px;

  padding: 10px 0;
  cursor: pointer;
  color: #fff;
  .iconfont {
    font-size: 40px;
  }
  &.fixed-wd {
    left: 5px;
  }
}
</style>
