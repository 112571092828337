import request from "@utils/request";

// 车辆管理
export default {
  // 缴费单价
  payMsgAmount(query) {
    return request({
      url: "/pay_msg_v2/amount",
      method: "get",
      params: query,
    });
  },
  // 缴费记录
  payMsgOpera(query) {
    return request({
      url: "/pay_msg_v2/list/by/car",
      method: "get",
      params: query,
    });
  },
  // 缴费列表
  payMsgList(query) {
    return request({
      url: "/pay_msg_v2/car/list",
      method: "get",
      params: query,
    });
  },
  // 详情
  payMsgDetail(query) {
    return request({
      url: "/pay_msg/info",
      method: "get",
      params: query,
    });
  },
  // 缴费状态
  payMsgStatus(data) {
    return request({
      url: "/pay_msg_v2/create",
      method: "post",
      data: data,
    });
  },
  //   删除
  payMsgDel(data) {
    console.log(`output->data`, data);
    return request({
      url: "/pay_msg/delete",
      method: "delete",
      data: data,
    });
  },
  //////////////////////售后配件更换
  //列表
  AfterSalesList(query) {
    return request({
      url: "/agvcar/accessories/replace/list",
      method: "get",
      params: query,
    });
  },

  //详情
  AfterSalesDetail(query) {
    return request({
      url: "/agvcar/accessories/replace/info",
      method: "get",
      params: query,
    });
  },

  //导出出
  exportData(query) {
    return request({
      url: "/agvcar/accessories/replace/export",
      method: "get",
      params: query,
      responseType: "blob",
    });
  },

  //远程锁定
  //列表
  RemoteLockList(query) {
    return request({
      url: "/agv/car/lock/list",
      method: "get",
      params: query,
    });
  },

  //锁定
  lock(data) {
    return request({
      url: "/agv/car/lock",
      method: "POST",
      data: data,
    });
  },

  //解除锁定
  unlock(data) {
    return request({
      url: "/agv/car/unlock",
      method: "POST",
      data: data,
    });
  },

  //锁定导出
  exportLock(query) {
    return request({
      url: "/agv/car/lock/excel",
      method: "get",
      params: query,
      responseType: "blob",
    });
  },

  //操作记录
  operate(query) {
    return request({
      url: "/agv/car/lock/history",
      method: "get",
      params: query,
    });
  },

  /////////////到期管理
  //列表
  expireList(query) {
    return request({
      url: "/expire/list",
      method: "get",
      params: query,
    });
  },
  //导出
  handleExcel(query) {
    return request({
      url: "/expire/excel",
      method: "get",
      params: query,
      responseType: "blob",
    });
  },
};
