import * as echarts from "echarts/core";
const MonitorScreenSprayQuantityChart = (data) => {
    let datacoords = [{
        coords: [],
    }, ];
    for (let i = 0; i < data.xData.length; i++) {
        datacoords[0].coords.push([data.xData[i], data.yData[i]]);
    }

    const defaultConfig = {
        // legend: {
        //     data: ["喷药量"],
        //     textStyle: {
        //         color: "#fff",
        //     },
        //     itemWidth: 10, // 设置图例宽度
        //     itemHeight: 10, // 设置图例高度
        //     right: 0, // 将图例移到右侧，距离右边界的距离
        //     top: 0,
        // },
        animation: true, //控制动画示否开启
        animationDuration: 3000,
        animationEasing: "bounceOut", //缓动动画
        animationThreshold: 8, //动画元素的阈值
        grid: {
            left: "1%",
            right: "3%",
            top: "25%",
            bottom: "1%",
            containLabel: true,
        },
        xAxis: {
            type: "category",
            data: data.xData,
            axisLine: {
                lineStyle: {
                    color: "#6D7A80",
                },
            },
            axisLabel: {
                color: "#fff",
            },
            axisTick: {
                show: false,
            },
            boundaryGap: false,
        },
        yAxis: {
            type: "value",
            min: 0,
            name: "单位:L",
            nameTextStyle: {
                color: 'rgba(255,255,255,0.5)',
                padding: [2, 2, 2, -10],
                fontSize: 14,
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: "#6D7A80",
                    type: "dotted",
                },
            },
            axisLabel: {
                color: "#fff",
            },
        },
        tooltip: {
            textStyle: {
                color: "rgba(255,255,255,0.6)",
            },
            backgroundColor: "rgba(71,255,255,0.14)",
            borderColor: "#47FFFF",
            trigger: "axis",
            formatter: function (params) {
                let tooltipString = params[0].name + "<br/>";
                params.forEach(function (item) {
                    var icon =
                        '<span style="display:inline-block;margin-right:5px;width:8px;height:8px;background-color:' +
                        item.color +
                        '"></span>';
                    var seriesName =
                        '<span style="font-size:12px;color:#fff; margin-right:30px;">' +
                        item.seriesName +
                        "</span>";
                    var value =
                        '<span style="color:#fff;font-size:14px;">' +
                        item.value + 'L'
                    " </span>";
                    tooltipString +=
                        '<div style="box-shadow: 6px 0px 10px 0px rgba(34,87,188,0.1); padding: 5px; margin-bottom: 5px; border-radius: 4px;">' +
                        icon +
                        seriesName +
                        value +
                        "</div>";
                });
                tooltipString += "</div>";
                return tooltipString;
            },
        },
        series: [{
                name: "喷药量",
                type: "line",
                symbol: "line",
                smooth: false,
                symbolSize: 6,
                itemStyle: {
                    color: "rgba(71, 255, 255, 1)",
                },
                lineStyle: {
                    color: "rgba(71, 255, 255, 1)",
                },
                emphasis: {
                    focus: "series",
                    itemStyle: {
                        color: "rgba(71, 255, 255, 1)",
                    },
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: "rgba(71,255,255,0.44)"
                        },
                        {
                            offset: 1,
                            color: "rgba(41,190,150,0)"
                        },
                    ]),
                },
                data: data.yData,
            },
            {
                showSymbol: false,
                name: "喷药量",
                type: "lines",
                polyline: true,
                smooth: false,
                coordinateSystem: "cartesian2d",
                zlevel: 1,
                effect: {
                    show: true,
                    smooth: true,
                    period: 6,
                    symbolSize: 6,
                },
                lineStyle: {
                    color: "#fff",
                    width: 1,
                    opacity: 0,
                    curveness: 0,
                    cap: "round",
                },
                data: datacoords,
            },
        ],
    };

    return defaultConfig;
};

export default {
    MonitorScreenSprayQuantityChart,
};