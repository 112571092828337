import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@views/Login";
import Layout from "@views/Layout";
import NotFound from "@views/404.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      hideMenu: true,
      title: "登录",
    },
  },
  {
    path: "/login-redirect",
    name: "LoginRedirect",
    component: () => import("@/views/Login/loginRedirect.vue"),
    meta: {
      hideMenu: true,
      title: "登录跳转",
    },
  },
  {
    path: "/bigScreen",
    name: "BigScreen",
    component: () => import("@/views/BigScreen"),
    meta: {
      hideMenu: true,
      title: "大屏",
    },
  },
  {
    path: "/CarScreen",
    name: "carScreen",
    component: () => import("@/views/CarScreen"),
    meta: {
      hideMenu: true,
      title: "车辆大屏",
    },
  },
  {
    path: "/CarMonitorScreen",
    name: "carMonitorScreen",
    component: () => import("@/views/CarMonitorScreen"),
    meta: {
      hideMenu: false,
      title: "车辆监测大屏",
    },
  },
  {
    path: "/CarMonitorScreen/ScreenDetail",
    name: "ScreenDetail",
    component: () => import("@/views/CarMonitorScreen/ScreenDetail"),
    meta: {
      hideMenu: false,
      title: "车辆监测大屏",
    },
  },
];
export const dynamicRoutes = [
  {
    path: "/",
    menu_url: "/",
    component: Layout,
    redirect: "/WebsiteMa",
    meta: {
      hideMenu: false,
    },
    children: [
      /* {
        path: "Home",
        menu_url: "/UnitMa",
        name: "Home",
        meta: { title: "首页", icon: "icon-unit" },
        component: NotFound,
      }, */
      /*{
        path: "ProductionMa",
        menu_url: "/ProductionMa",
        name: "ProductionMa",
        meta: { title: "生产管理", icon: "icon-product", code: "production" },
        component: () => import("@/views/ProductionMa/"),
        redirect: "/ProductionMa/CarProtect",
        children: [
          {
            path: "IntellPlatform",
            menu_url: "/ProductionMa/IntellPlatform",
            name: "CarProtect",
            meta: { title: "智能补给点管理", icon: "", code: "nanny_car" },
            component: () => import("@/views/ProductionMa/IntellPlatform"),
          },*/
      /* {
            path: "CarProtect",
            menu_url: "/ProductionMa/CarProtect",
            name: "CarProtect",
            meta: { title: "智能补给平台管理", icon: "", code: "nanny_car" },
            component: () => import("@/views/ProductionMa/CarProtectMa"),
          },
          {
            path: "MedicineMa",
            menu_url: "/ProductionMa/MedicineMa",
            name: "MedicineMa",
            meta: {
              title: "药水种类管理",
              icon: "",
              code: "production_yaoshui",
            },
            component: () => import("@/views/ProductionMa/MedicineMa"),
          },
          {
            path: "CarDriverless",
            menu_url: "/ProductionMa/CarDriverless",
            name: "CarDriverless",
            meta: { title: "果园机器人管理", icon: "", code: "agv_car" },
            component: () => import("@/views/ProductionMa/CarDriverless"),
          },
          {
            path: "VehicleInfo",
            menu_url: "/ProductionMa/VehicleInfo",
            name: "VehicleInfo",
            meta: { title: "状态信息管理", icon: "", code: "status_info" },
            component: () => import("@/views/ProductionMa/VehicleInfo"),
          },
          {
            path: "SharedPath",
            menu_url: "/ProductionMa/SharedPath",
            name: "SharedPath",
            meta: { title: "共享路径管理", icon: "", code: "shared_path" },
            component: () => import("@/views/ProductionMa/SharedPath"),
          }, */
      /* ],
      },*/
      // {
      //   path: "DataMaintenance",
      //   menu_url: "/DataMaintenance",
      //   name: "DataMaintenance",
      //   meta: { title: "基础数据维护", icon: "icon-a-zu331" },
      //   component: () => import("@/views/DataMaintenance/"),
      //   children: [
      //     {
      //       path: "DataMaintenance",
      //       menu_url: "/DataMaintenance",
      //       name: "DataMaintenance",
      //       meta: { title: "数据维护" },
      //       component: () => import("@/views/DataMaintenance/"),
      //     },
      //   ],
      // },
      /* {
        path: "QrcodeMa",
        menu_url: "/QrcodeMa",
        name: "QrcodeMa",
        meta: { title: "铭牌管理", icon: "icon-product", code: "qrcode" },
        component: () => import("@/views/QrcodeMa/"),
        redirect: "/QrcodeMa/NumberArg",
        children: [
          {
            path: "NumberArg",
            menu_url: "/QrcodeMa/NumberArg",
            name: "NumberArg",
            meta: { title: "产品代号参数管理", icon: "", code: "qrcodeMa" },
            component: () => import("@/views/QrcodeMa/NumberArg"),
          },

          {
            path: "QrCreate",
            menu_url: "/QrcodeMa/QrCreate",
            name: "QrCreate",
            meta: { title: "二维码生成管理", icon: "", code: "QrCreate" },
            component: () => import("@/views/QrcodeMa/QrCreate"),
          },
        ],
      }, */
    ],
  },
  {
    path: "*",
    component: NotFound,
    meta: {
      hideMenu: true,
    },
  },
];
const router = new VueRouter({
  // routes: routes.concat(dynamicRoutes),
  routes,
});

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const cssPattern = /Loading CSS chunk (\d)+ failed/g;
  const isChunkLoadFailed =
    error.message.match(pattern) || error.message.match(cssPattern);
  if (isChunkLoadFailed) {
    // 用路由的replace方法，并没有相当于F5刷新页面，失败的js文件并没有从新请求，会导致一直尝试replace页面导致死循环，而用 location.reload 方法，相当于触发F5刷新页面，虽然用户体验上来说会有刷新加载察觉，但不会导致页面卡死及死循环，从而曲线救国解决该问题
    location.reload();
    // const targetPath = $router.history.pending.fullPath;
    // $router.replace(targetPath);
  }
});

export default router;
