import request from "@utils/request";

const urlParams = {
  policyUserList: "/policy/user/list", // 获取所有人员
  policyUserGet: "/policy/user/get", // 获取该人员有哪些权限
  policyUserSet: "/policy/user/set", // 设置权限
};

// 查询所有系统配置
export default {
  policyUserList() {
    return request({
      url: urlParams.policyUserList,
      method: "get",
    });
  },
  policyUserGet(data) {
    return request({
      url: urlParams.policyUserGet,
      method: "get",
      params: data,
    });
  },
  policyUserSet(data) {
    return request({
      url: urlParams.policyUserSet,
      method: "post",
      data,
    });
  },
};
