<template>
  <div class="head__com flex align-center justify-between">
    <HeadMenu />
    <HeadUser />
  </div>
</template>

<script>
import HeadUser from "./HeadUser.vue";
import HeadMenu from "./HeadMenu.vue";
export default {
  components: { HeadUser, HeadMenu },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.head__com {
  padding: 0 24px;
  height: 64px;
  background: #242424;
}
</style>
