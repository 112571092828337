<template>
  <a-sub-menu :key="menuInfo.menu_url" v-bind="$props" v-on="$listeners">
    <span slot="title">
      <i :class="['iconfont  m-r-10', menuInfo.meta.icon]" />
      <span class="menu_name">{{ menuInfo.title }}</span>
    </span>
    <template v-for="(item, index) in menuInfo.children">
      <template v-if="!item.meta.hideMenu">
        <a-menu-item v-if="!item.children" :key="item.menu_url">
          <i :class="['iconfont m-r-10', item.meta.icon]" />
          <span>{{ item.title }}</span>
        </a-menu-item>
        <sub-menu v-else :key="item.menu_url + index" :menu-info="item" />
      </template>
    </template>
  </a-sub-menu>
</template>
<script>
import { Menu } from "ant-design-vue";
export default {
  name: "SubMenu",
  isSubMenu: true,
  props: {
    // 解构a-sub-menu的属性，也就是文章开头提到的为什么使用函数式组件
    ...Menu.SubMenu.props,
    // 接收父级传递过来的菜单信息
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {},
};
</script>
