import storage from "@/utils/storage";

const state = {
  agv_car_id: "",
  timeObj: {},
  empty: "",
  reset: "",
};

const getters = {};

const mutations = {
  AGV_CAR_ID(state, value) {
    state.agv_car_id = value;
    storage.setItem("agv_car_id", value);
  },
  TIME_OBJ(state, value) {
    state.timeObj = value;
    // storage.setItem("timeObj", value);
    console.log(value, "1111");
  },
  EMPTY_TIME_OBJ(state, value) {
    state.empty = value;
  },
  RESET_TIME_OBJ(state, value) {
    state.reset = value;
  },
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
