/*
 * @Author: 殷琦
 * @Date: 2022-09-03
 * @LastEditTime: 2022-09-03
 * @LastEditors: 殷琦
 * @Description: 验证工具
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ["admin", "editor"];
  return valid_map.indexOf(str.trim()) >= 0;
}

/* 合法uri*/
export function validateURL(textval) {
  const urlregex =
    /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return urlregex.test(textval);
}

/* 小写字母*/
export function validateLowerCase(str) {
  const reg = /^[a-z]+$/;
  return reg.test(str);
}

/* 大写字母*/
export function validateUpperCase(str) {
  const reg = /^[A-Z]+$/;
  return reg.test(str);
}

/* 大小写字母*/
export function validatAlphabets(str) {
  const reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

/* 验证数字包含两位小数*/
export function isValidateNumberAndPoint(str) {
  const reg = /^\d+(\.\d{1,2})?$/;
  return reg.test(str);
}

/* 验证数字包含两位小数*/
export function isValidateNumber(str) {
  const reg = /^\d+?$/;
  return reg.test(str);
}

/**
 * 手机正则
 * @type {RegExp}
 */
export const phonePattern = /^1[3|4|5|7|8][0-9]\d{8}$/;
/* 验证手机号*/
export function validatePhone(phone) {
  const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
  return reg.test(phone);
}

/**
 * 验证身份证
 * @param id
 * @returns {*}
 */
export function validateId(id) {
  const reg =
    /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$)/;
  return reg.test(id);
}

/**
 * 验证护照
 *  护照
 * 规则： 14/15开头 + 7位数字, G + 8位数字, P + 7位数字, S/D + 7或8位数字,等
 * 样本： 141234567, G12345678, P1234567
 * @param passPort
 * @returns {boolean}
 */
export function validatePassPort(passPort) {
  const reg = /^([a-zA-z]|[0-9]){5,17}$/;
  return reg.test(passPort);
}
