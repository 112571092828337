import request from "@utils/request";

// 查询所有系统配置
export default {
  newsCategoryList(data) {
    return request({
      url: "/news/category/list",
      method: "get",
      params: data,
    });
  },
  newsAdd(data) {
    return request({
      url: "/news/add",
      method: "post",
      data,
    });
  },

  newsList(data) {
    return request({
      url: "/news/list",
      method: "get",
      params: data,
    });
  },
  newsInfo(data) {
    return request({
      url: "/news/info",
      method: "get",
      params: data,
    });
  },

  newsEdit(data) {
    return request({
      url: "/news/edit",
      method: "put",
      data,
    });
  },
  newsDel(data) {
    return request({
      url: "/news/del",
      method: "delete",
      data,
    });
  },
};
