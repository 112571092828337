const carAreaChart = (data) => {
    const defaultConfig = {
        tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid: {
            top: "15%",
            left: "0%",
            right: "0%",
            bottom: "10%",
            containLabel: true,
          },
          legend: {
            top: "top",
            left: "0%",
            type: "scroll", //过多类型时自适应分页显示
            orient: "horizontal", //方向horizontal水平显示，vertical垂直显示
            data: data.legendData,
            itemWidth: 10,
            itemHeight: 10,
            itemGap: 20,
            textStyle: {
              fontSize: 12,
              color: "#000000E5",
              padding: [0, 0, 0, 2],
            },
          },
          // 滚动滑块
          // dataZoom: [
          //   {
          //     type: "slider", //slider表示有滑动块的，
          //     show: true,
          //     xAxisIndex: 0,
          //     start: 0,
          //     end: 100,
          //     height: 8,
          //     bottom: 0,
          //   },
          // ],
          dataZoom: [
            {
              type: "slider", //slider表示有滑动块的，inside表示内置的
              show: data.xData.length > 0 ? true : false,
              backgroundColor: "#EDEEF0", //组件的背景颜色
              height: 8,
              xAxisIndex: 0,
              bottom: 10,
              start: 0,
              end: 100,
              handleSize: 8,
              minSpan: 6, //最小滑动距离
              showDetail: false,
              filterMode: "filter",
              borderColor: "#EDEEF0FF", //边框颜色
              fillerColor: "#BBBDBF", //选中范围背景色
              moveOnMouseMove: true,
              selectedDataBackground: {
                lineStyle: {
                  color: "#EDEEF0FF",
                },
                areaStyle: {
                  color: "#fff",
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                  shadowBlur: 5,
                },
              },
              // 用于画手柄
              handleIcon:
                // "M0,0 v9.7h5 v-9.7h-5 Z", // 画一个长方形
                "path://M30.9,53.2C16.8,53.2,5.3,41.7,5.3,27.6S16.8,2,30.9,2C45,2,56.4,13.5,56.4,27.6S45,53.2,30.9,53.2z M30.9,3.5M36.9,35.8h-1.3z M27.8,35.8 h-1.3H27L27.8,35.8L27.8,35.8z", // 画一个圆形
              handleStyle: {
                color: "#999",
                global: true, // 缺省为 false
                shadowBlur: 20,
                shadowColor: "rgba(123, 154, 204, 0.5)",
                shadowOffsetX: 0, // 阴影偏移x轴多少
                shadowOffsetY: 0, // 阴影偏移y轴多少
              },
            },
          ],
          xAxis: {
            axisLabel: {
              textStyle: {
                color: "#00000099",
                fontSize: "12px",
              },
            },
            axisLine: {
              show: false,
            },
            data: data.xData,
            axisTick: {
              show: false, //隐藏x轴刻度
            },
          },
          yAxis: {
            min: 0,
            minInterval: 1,
            axisLabel: {
              textStyle: {
                color: "#00000099",
                fontSize: "12px",
              },
            },
          },
          series: data.SeriesData,
    };
  
    const opt = Object.assign({}, defaultConfig);
    return opt;
  };
  
  export default {
    carAreaChart,
  };
  