import request from "@utils/request";

export default {
  //未销售车辆列表
  unsoldList(data) {
    return request({
      url: "/agv/car/manage/unsold/list",
      method: "get",
      params: data,
    });
  },
  //已销售车辆列表
  soldList(data) {
    return request({
      url: "/agv/car/manage/sold/list",
      method: "get",
      params: data,
    });
  },
  //车辆类型
  carTypeList(data) {
    return request({
      url: "/product_model/all/list",
      method: "get",
      params: data,
    });
  },
  //代理商列表
  companyAgentList(data) {
    return request({
      url: "/agent/list",
      method: "get",
      params: data,
    });
  },
  // 代理商客户列表
  agentCompanyList(data) {
    return request({
      url: "/agent/company/list",
      method: "get",
      params: data,
    });
  },
  //单位列表
  companyDirectSelling(data) {
    return request({
      url: "/company/list/by/directSelling",
      method: "get",
      params: data,
    });
  },
  //代理商解绑
  agentUnbindCar(data) {
    return request({
      url: "/agent/unbind_car",
      method: "put",
      data: data,
    });
  },
  //直销详情
  companyUnbindCar(data) {
    return request({
      url: "/agvCar/company/unbind",
      method: "put",
      data: data,
    });
  },
  //车辆详情
  agvCarDetail(data) {
    return request({
      url: "/agvCar/detail",
      method: "get",
      params: data,
    });
  },

  //获取代理商用户列表
  agentCompanyBindingList(data) {
    return request({
      url: "/agent/company/binding/list",
      method: "get",
      params: data,
    });
  },
  //绑定代理商车辆客户信息
  agentCompanyBinding(data) {
    return request({
      url: "/agent/company/agvcar/binding",
      method: "post",
      data: data,
    });
  },
  //解绑代理商车辆客户信息
  agentCompanyUnBinding(data) {
    return request({
      url: "/agent/company/agvcar/un/binding",
      method: "post",
      data: data,
    });
  },
  //获取单位详情
  agentCompanyInfo(data) {
    return request({
      url: "/agent/company/info",
      method: "get",
      params: data,
    });
  },
};