const state = {
  dialogInfo: {},
};

const getters = {};

const mutations = {
  SET_DIALOG_INFO(state, value) {
    state.dialogInfo = value;
  },
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
