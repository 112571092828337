const BigScreenDistributionChart = (data) => {
  let defaultShowNum = 10; // 默认显示的数据条数
  let start = 0;
  let end = Math.min(defaultShowNum, data.xData.length) / data.xData.length * 100;
  const defaultConfig = {
    legend: {
      show: true,
      top: "4%",
      right: "3%",
      textStyle: {
        color: "rgba(212, 255, 253, 1)",
        fontSize: 12,
      },
      icon: "square",
      itemWidth: 10,
      itemHeight: 10,
      // formatter: (name) => {
      //   return `${name}\t${obj[name]}`;
      // },
    },
    tooltip: {
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
        fontSize: 9
      },
      backgroundColor: "rgba(24,144,255,0.3)", //背景色
      borderColor: "rgba(24,144,255,0.3)", // 边框颜色
      trigger: "axis",
      // axisPointer: {
      //   type: "shadow",
      // },
      formatter: function (params) {
        console.log(`output->params,'params`, params)
        let tooltipString = params[0].name + "<br/>";
        params.forEach(function (item) {
          if (item.componentSubType == "bar") {
            var icon =
              '<span style="display:inline-block;margin-right:5px;width:6px;height:6px;background-color:' +
              item.borderColor +
              '"></span>';
            var seriesName =
              '<span style="color: rgba(255,255,255,0.85);margin-right:20px;font-size:8px;">' +
              item.seriesName +
              "</span>";
            var value =
              '<span style="color:rgba(255,255,255,0.85);font-size:8px;">' +
              item.value +
              "</span>";
            tooltipString +=
              '<div style="padding: 2px; margin-bottom: 1px; border-radius: 4px;">' +
              icon +
              seriesName +
              value +
              "</div>";
          }
        });
        tooltipString += "</div>";
        return tooltipString;
      },
    },
    dataZoom: [{
        show: true,
        // show: data.xData.length > 10 ? true : false,
        backgroundColor: "rgba(24, 144, 255, 0.20)",
        borderColor: "rgba(24, 144, 255, 0.20)", //边框颜色
        fillerColor: "rgba(141, 200, 255, 0.40)", //选中范围背景色
        height: 7,
        xAxisIndex: 0,
        bottom: 5,
        left: 0,
        right: 0,
        brushSelect: false,
        start: start,
        end: end,
        handleSize: 6,
        minSpan: 20, //最小滑动距离
        showDetail: false,
        filterMode: "filter",
        moveOnMouseMove: true,
        selectedDataBackground: {
          lineStyle: {
            color: "rgba(24, 144, 255, 0.20)",
          },
          areaStyle: {
            color: "rgba(24, 144, 255, 0.20)",
            shadowColor: "rgba(24, 144, 255, 0.20)",
            shadowBlur: 5,
          },
        },
        // 用于画手柄
        handleIcon:
          // "M0,0 v9.7h5 v-9.7h-5 Z", // 画一个长方形
          "path://M30.9,53.2C16.8,53.2,5.3,41.7,5.3,27.6S16.8,2,30.9,2C45,2,56.4,13.5,56.4,27.6S45,53.2,30.9,53.2z M30.9,3.5M36.9,35.8h-1.3z M27.8,35.8 h-1.3H27L27.8,35.8L27.8,35.8z", // 画一个圆形
        handleStyle: {
          color: "rgba(24, 144, 255, 0.20)",
          border: '1px solid #6FB1F3',
          global: true, // 缺省为 false
          shadowBlur: 10,
          shadowColor: "rgba(24, 144, 255, 0.20)",
          shadowOffsetX: 0, // 阴影偏移x轴多少
          shadowOffsetY: 0, // 阴影偏移y轴多少
        },
      },
      {
        type: "inside",
      },
    ],
    grid: {
      left: 0,
      right: 0,
      top: '18%',
      bottom: '15%',
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: data.xData,
      axisLine: {
        lineStyle: {
          color: 'rgba(168,214,255,0.7)',
        },
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: 'rgba(168,214,255,0.7)',
          fontSize: 10,
          interval: 0
        },
        formatter: function (value) {
          if (value.length > 3) {
            return `${value.slice(0, 3)}...`
          }
          return value
        }
      },
      axisTick: {
        show: false,
      },

    },
    title: {
      text: "单位:辆",
      top: 10,
      left: 0,
      textStyle: {
        color: '#A8D6FF',
        fontSize: 12
      },
    },
    yAxis: {
      type: "value",
      min: 0,
      minInterval: 1,
      splitLine: {
        lineStyle: {
          color: "rgba(40, 107, 228, 0.20)",
          type: "dotted",
        },
      },
      axisLabel: {
        fontSize: 12,
        color: 'rgba(168,214,255,0.7)',
      },
    },
    animationEasing: "linear",
    animationEasingUpdate: "quadraticIn", //数据更新时的缓动效果
    animationDurationUpdate: 1000, //数据更新动画的时长
    animation: true, //开启动画
    series: [{
        name: "在线",
        type: "bar",
        label: {
          show: false,
        },
        barWidth: "16",
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 0,
              color: 'rgba(1,173,249,0)' // 100% 处的颜色
            }, {
              offset: 1,
              color: 'rgba(168,214,255,0.7)' // 0% 处的颜色
            }],
          },
          borderColor: '#4378DB', // 边框颜色
          borderWidth: 1, // 边框宽度
        },
        data: data.yData0,
      },
      {
        name: "离线",
        type: "bar",
        label: {
          show: false,
        },
        barWidth: 16, // 柱子宽度
        // barCategoryGap: 5, // 柱子之间的间距
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 0,
              color: 'rgba(255,205,62,0)' // 0% 处的颜色
            }, {
              offset: 1,
              color: 'rgba(255, 205, 62, 1)' // 99% 处的颜色
            }],
          },
          borderColor: '#FFCD3E', // 边框颜色
          borderWidth: 1, // 边框宽度
        },
        data: data.yData1,
      }
    ],
  };

  const opt = Object.assign({}, defaultConfig);
  return opt;
};

export default {
  BigScreenDistributionChart,
};