import request from "@utils/request";

// 查询所有系统配置
export default {
  login(data) {
    return request({
      url: "/login",
      method: "post",
      data,
    });
  },
  dingUrl: (window.config.BASE_URL || process.env.VUE_APP_BASE_API) + "dd/jump_login?redirect_uri=",
  ddLogin(authCode) {
    return request({
      url: "/dd/auth?authCode=" + authCode,
      method: "post",
    });
  },
};
