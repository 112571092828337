import request from "@utils/request";

// 查询所有系统配置
export default {
  listAll() {
    return request({
      url: "/policy/list/all",
      method: "get",
    });
  },
  addMenu(data) {
    return request({
      url: "/policy/add/menu",
      method: "post",
      data,
    });
  },
  editMenu(data) {
    return request({
      url: "/policy/edit/menu",
      method: "put",
      data,
    });
  },
  deleteMenu(data) {
    return request({
      url: "/policy/delete/menu",
      method: "delete",
      data,
    });
  },
  addCom(data) {
    return request({
      url: "/policy/add/menu/assembly",
      method: "post",
      data,
    });
  },
  editCom(data) {
    return request({
      url: "/policy/edit/menu/assembly",
      method: "put",
      data,
    });
  },
  deleteCom(data) {
    return request({
      url: "/policy/delete/menu/assembly",
      method: "delete",
      data,
    });
  },
  policyMy() {
    return request({
      url: "/policy/my",
      method: "get",
    });
  },
};
