import EmptyIcon from "@/components/EmptyIcon";
export default {
  components: { EmptyIcon },
  data() {
    return {
      locale: {
        emptyText: <empty-icon />,
      },
    };
  },
};
