const MonitorScreenCityChart = (data) => {
  const defaultConfig = {
    legend: {
      show: true,
      top: "5%",
      right: "3%",
      textStyle: {
        color: "#fff",
        fontSize: 12,
      },
      icon: "square",
      itemWidth: 10,
      itemHeight: 10,
    },
    tooltip: {
      trigger: "axis",
      backgroundColor: "rgba(1,173,249,0.2)",
      borderColor: "#47FFFF",
      borderWidth: 1,
      textStyle: {
        color: "#fff",
        fontSize: 14,
        fontWeight: 500,
        align: "left",
      },
      axisPointer: {
        type: "shadow",
        shadowStyle: {
          shadowBlur: 1,
        },
      },
    },
    grid: {
      left: 0,
      right: 0,
      top: '20%',
      bottom: 0,
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: data.xData,
      axisLine: {
        lineStyle: {
          color: "#6D7A80FF",
        },
      },
      axisLabel: {
        color: "rgba(255,255,255,0.7)",
        fontSize: 12,
        interval: 0,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      min: 0,
      name: "单位:辆",
      nameTextStyle: {
        color: 'rgba(255,255,255,0.5)',
        padding: [2, 2, 2, 2],
      },
      splitLine: {
        lineStyle: {
          color: "#6D7A80FF",
          type: "line",
        },
      },
      axisLabel: {
        fontSize: 12,
        color: 'rgba(255, 255, 255, 0.6)',
      },
    },
    series: [{
        name: "在线",
        type: "bar",
        stack: "total",
        label: {
          show: false,
        },
        barWidth: "16",
        itemStyle: {
          color: "#01ABF8FF",
        },
        data: [20, 18, 25, 28, 25],
        animationDelay: (idx) => idx * 100, // 动画延迟
      },
      {
        name: "离线",
        type: "bar",
        stack: "total",
        label: {
          show: false,
        },
        barWidth: "16",
        itemStyle: {
          color: "#FFCD3EFF",
        },
        data: [10, 15, 18, 19, 10],
        animationDelay: (idx) => idx * 100, // 动画延迟
      },
    ],
    animationEasing: 'cubicOut', // 动画缓动函数
    animationDuration: 1500, // 动画持续时间
    animationDelayUpdate: (idx) => idx * 100, // 更新动画延迟
  };

  const opt = Object.assign({}, defaultConfig);
  return opt;
};

export default {
  MonitorScreenCityChart,
};