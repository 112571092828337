import request from "@utils/request";

// 查询所有系统配置
export default {
  fileUpload(data) {
    return request({
      url: "/file/upload",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "post",
      data,
    });
  },
  categoryAdd(data) {
    return request({
      url: "/solutions/category/add",
      method: "post",
      data,
    });
  },
  solutionsAdd(data) {
    return request({
      url: "/solutions/add",
      method: "post",
      data,
    });
  },
  categoryList(data) {
    return request({
      url: "/solutions/category/list",
      method: "get",
      params: data,
    });
  },
  solutionsList(data) {
    return request({
      url: "/solutions/list",
      method: "get",
      params: data,
    });
  },
  categoryInfo(data) {
    return request({
      url: "/solutions/category/info",
      method: "get",
      params: data,
    });
  },
  solutionsInfo(data) {
    return request({
      url: "/solutions/info",
      method: "get",
      params: data,
    });
  },
  categoryEdit(data) {
    return request({
      url: "/solutions/category/edit",
      method: "put",
      data,
    });
  },
  solutionsEdit(data) {
    return request({
      url: "/solutions/edit",
      method: "put",
      data,
    });
  },
  categoryDel(data) {
    return request({
      url: "/solutions/category/del",
      method: "delete",
      data,
    });
  },
  solutionsDel(data) {
    return request({
      url: "/solutions/del",
      method: "delete",
      data,
    });
  },
};
