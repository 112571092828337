import * as echarts from "echarts/core";

const MonitorScreenAcresChart = (data) => {
  // 绘制左侧面
  const CubeLeft = echarts.graphic.extendShape({
    shape: {
      x: 0,
      y: 0
    },
    buildPath: function (ctx, shape) {
      // 会canvas的应该都能看得懂，shape是从custom传入的
      const xAxisPoint = shape.xAxisPoint
      const c0 = [shape.x, shape.y]
      const c1 = [shape.x - 13, shape.y - 13]
      const c2 = [xAxisPoint[0] - 13, xAxisPoint[1] - 13]
      const c3 = [xAxisPoint[0], xAxisPoint[1]]
      ctx.moveTo(c0[0], c0[1]).lineTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).closePath()
    }
  })
  // 绘制右侧面
  const CubeRight = echarts.graphic.extendShape({
    shape: {
      x: 0,
      y: 0
    },
    buildPath: function (ctx, shape) {
      const xAxisPoint = shape.xAxisPoint
      const c1 = [shape.x, shape.y]
      const c2 = [xAxisPoint[0], xAxisPoint[1]]
      const c3 = [xAxisPoint[0] + 18, xAxisPoint[1] - 9]
      const c4 = [shape.x + 18, shape.y - 9]
      ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1]).closePath()
    }
  })
  // 绘制顶面
  const CubeTop = echarts.graphic.extendShape({
    shape: {
      x: 0,
      y: 0,
    },
    buildPath: function (ctx, shape) {
      const c1 = [shape.x, shape.y]
      const c2 = [shape.x + 18, shape.y - 9]
      const c3 = [shape.x + 5, shape.y - 22]
      const c4 = [shape.x - 13, shape.y - 13]
      ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1]).closePath()
    }
  })
  // 注册三个面图形
  echarts.graphic.registerShape('CubeLeft', CubeLeft)
  echarts.graphic.registerShape('CubeRight', CubeRight)
  echarts.graphic.registerShape('CubeTop', CubeTop)


  const defaultConfig = {
    legend: {
      show: true,
      top: "5%",
      right: "3%",
      textStyle: {
        color: "#fff",
        fontSize: 12,
      },
      icon: "square",
      itemWidth: 10,
      itemHeight: 10,
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: "rgba(1,173,249,0.5)",
      borderColor: "#47FFFF",
      borderWidth: 1,
      textStyle: {
        color: "#fff",
        fontSize: 14,
        fontWeight: 500,
        align: "left",
      },
      axisPointer: {
        type: 'shadow'
      },
      formatter: function (params) {
        const item = params[1]
        return item.name + ' : ' + item.value + '亩';
      }
    },
    grid: {
      left: 0,
      right: 0,
      bottom: 0,
      top: '20%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: data.xData,
      axisLine: {
        lineStyle: {
          color: "#6D7A80FF",
        },
      },
      // offset: 25,
      axisTick: {
        show: false,
      },
      axisLabel: {
        color: "rgba(255,255,255,0.7)",
        fontSize: 12,
        interval: 0,
      },
    },
    yAxis: {
      type: 'value',
      min: 0,
      name: "单位:亩",
      nameTextStyle: {
        color: 'rgba(255,255,255,0.5)',
        padding: [2, 2, 2, -10],
      },
      splitLine: {
        show: false,
        lineStyle: {
          type: "dashed",
          color: "#6D7A80FF",
        },
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: true,
        fontSize: 12,
        color: 'rgba(255, 255, 255, 0.6)',
      },
      boundaryGap: ['20%', '20%']
    },
    series: [{
      type: 'custom',
      renderItem: function (params, api) {
        const location = api.coord([api.value(0), api.value(1)])
        return {
          type: 'group',
          children: [{
            type: 'CubeLeft',
            shape: {
              api,
              x: location[0],
              y: location[1],
              xAxisPoint: api.coord([api.value(0), 0])
            },
            style: {
              fill: 'rgba(71, 255, 255,.27)'
            }
          }, {
            type: 'CubeRight',
            shape: {
              api,
              x: location[0],
              y: location[1],
              xAxisPoint: api.coord([api.value(0), 0])
            },
            style: {
              fill: 'rgba(71, 255, 255,.37)'
            }
          }, {
            type: 'CubeTop',
            shape: {
              api,
              x: location[0],
              y: location[1],
              xAxisPoint: api.coord([api.value(0), 0])
            },
            style: {
              fill: 'rgba(72,255,221,.27)'
            }
          }]
        }
      },
      data: data.MAX
    }, {
      name: "作业时长",
      type: 'custom',
      renderItem: (params, api) => {
        const location = api.coord([api.value(0), api.value(1)])
        var color = api.value(1) > 800 ? 'red' : new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: 'rgba(71, 255, 255, 1)'
          },
          {
            offset: 0.8,
            color: 'rgba(1, 173, 249, 1)'
          }
        ])
        return {
          type: 'group',
          children: [{
            type: 'CubeLeft',
            shape: {
              api,
              xValue: api.value(0),
              yValue: api.value(1),
              x: location[0],
              y: location[1],
              xAxisPoint: api.coord([api.value(0), 0])
            },
            style: {
              fill: color
            }
          }, {
            type: 'CubeRight',
            shape: {
              api,
              xValue: api.value(0),
              yValue: api.value(1),
              x: location[0],
              y: location[1],
              xAxisPoint: api.coord([api.value(0), 0])
            },
            style: {
              fill: color
            }
          }, {
            type: 'CubeTop',
            shape: {
              api,
              xValue: api.value(0),
              yValue: api.value(1),
              x: location[0],
              y: location[1],
              xAxisPoint: api.coord([api.value(0), 0])
            },
            style: {
              fill: color
            }
          }]
        }
      },
      data: data.yData,
    }, {
      type: 'bar',
      label: {
        normal: {
          show: false,
          position: 'top',
          fontSize: 12,
          color: '#fff',
          offset: [2, -25]
        }
      },
      itemStyle: {
        color: 'transparent',
      },
      tooltip: {},
      data: data.MAX
    }]
  }

  const opt = Object.assign({}, defaultConfig);
  return opt;
};

export default {
  MonitorScreenAcresChart,
};