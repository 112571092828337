import request from "@utils/request";

export default {
  // 代理商列表
  agentList(data) {
    return request({
      url: "/agent/list",
      method: "get",
      params: data,
    });
  },
  // 代理商绑定车辆列表
  agentBindList(data) {
    return request({
      url: "/agent/car/bind_list",
      method: "get",
      params: data,
    });
  },
  // 代理商新增
  agentAdd(data) {
    return request({
      url: "/agent/add",
      method: "post",
      data,
    });
  },
  // 编辑
  agentUpdate(data) {
    return request({
      url: "/agent/update",
      method: "put",
      data: data,
    });
  },
  // 删除
  agentDelete(data) {
    return request({
      url: "/agent/delete",
      method: "delete",
      data: data,
    });
  },
  // 详情
  agentDetail(data) {
    return request({
      url: "/agent/detail",
      method: "get",
      params: data,
    });
  },
  // 上传
  fileUpload(data) {
    return request({
      url: "/file/upload",
      method: "post",
      data,
    });
  },
  // 绑定
  agentBindCar(data) {
    return request({
      url: "/agent/bind_car",
      method: "put",
      data: data,
    });
  },
  // 录入设备详情
  userBandeqipInfo(data) {
    return request({
      url: "/agvCar/info/by/factoryno",
      method: "get",
      params: data,
    });
  },
  // 代理商下单位列表
  companyList(data) {
    return request({
      url: "/agent/company/list",
      method: "get",
      params: data,
    });
  },
  //单个搜索
  companyListSearch(data) {
    return request({
      url: "/company/searchList",
      method: "get",
      params: data,
    });
  },
  //   新增客户单位
  companyAdd(data) {
    return request({
      url: "/agent/company/add",
      method: "post",
      data,
    });
  },
  //   编辑客户单位
  companyEdit(data) {
    return request({
      url: "/company/edit",
      method: "put",
      data,
    });
  },
  //   删除客户单位
  companyDel(data) {
    return request({
      url: "/agent/company/delete",
      method: "delete",
      data,
    });
  },
  //   单位信息
  companyInfo(data) {
    return request({
      url: "/company/info",
      method: "get",
      params: data,
    });
  },
  //  单位下车辆列表
  companyCarList(data) {
    return request({
      url: "/agvCar/list/by/company",
      method: "get",
      params: data,
    });
  },

  //  客户新增
  companyUserAdd(data) {
    return request({
      url: "/companyUser/add",
      method: "post",
      data,
    });
  },
  //   客户编辑
  companyUserEdit(data) {
    return request({
      url: "/companyUser/edit",
      method: "put",
      data,
    });
  },
  //   客户删除
  companyUserDel(data) {
    return request({
      url: "/companyUser/del",
      method: "delete",
      data,
    });
  },
  //   种植类型
  speciesList(data) {
    return request({
      url: "/farm/species/system/list",
      method: "get",
      params: data,
    });
  },
};
