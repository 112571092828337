/* eslint-disable no-unused-vars */
// import {
//   agvAlarmList,
//   agvPotionList,
//   agvHistoryList,
//   pathListApi,
// } from "@/api/FarmMachine";
import {
  companyList,
  carList,
  carTypeList,
  carWarn,
  carStatusInfo,
  trackList,
  agvAlarmList,
  agvPotionList,
  agvHistoryList,
  mapList,
  summaryList,
  summary,
  imgList,
  historyAppear,
} from "@/api/ProductionMa/Work";
import Vue from "vue";
const state = {
  currented: 1,
  agvItem: null,
  opeSearchParams: {
    page_index: 1,
    /* page_size: 1,
    car_code: "test", */
    page_size: 20,
    agv_car_id: "",
    begin_time: "",
    end_time: "",
  },
  opeList: [],
  opeTotal: 0,
  pathTotal: 0,
  imgTotal: 0,
  imgList: [],
  pathList: [],
  loading: false,
  potionSearchParams: {
    page_index: 1,
    /*  page_size: 1,
    agv_car_id: "3p9wn1", */
    page_size: 20,
    agv_car_id: "",
    nanny_car_id: "",
    day: "",
    end_days: "",
  },
  poTotal: 0,
  poList: [],
  historySearchParams: {
    page_index: 1,
    /* page_size: 1,
    agv_car_id: "37rr3e", */
    page_size: 20,
    agv_car_id: "",
    begin_time: "",
    end_time: "",
  },
  historyTotal: 0,
  historyList: [],
  pathSearchParams: {
    page_index: 1,
    /*  page_size: 1,
    agv_car_id: "345ljd", */
    page_size: 20,
    agv_car_id: "",
    start_time: "",
    end_time: "",
  },
  imgSearchParams: {
    agv_car_id: "",
    start_time: "",
    end_time: "",
  },
  pathParams: {
    page_index: 1,
    /*  page_size: 1,
    agv_car_id: "345ljd", */
    page_size: 20,
    agv_car_id: "",
    start_time: "",
    end_time: "",
  },
  warningTotal: 0,
  warningList: [],
  warningSearchParams: {
    page_index: 1,
    start_time: "",
    end_time: "",
    page_size: 20,
  },
  companyListData: [],
  carTypeListData: [],
  carListData: [],
  carWarnListData: [],
  companyTotal: 0,
};

const mutations = {
  SET_CURRENT(state, value) {
    state.currented = value;
  },
  setLoading(state, load) {
    state.loading = load;
  },
  setAgvItem(state, item) {
    state.agvItem = item;
  },
  setOpeSearchParams(state, searchParams) {
    state.opeSearchParams = { ...state.opeSearchParams, ...searchParams };
  },
  setImageTotal(state, total) {
    state.imgTotal = total;
  },
  setOpeTotal(state, total) {
    state.opeTotal = total;
  },
  setPathTotal(state, total) {
    state.pathTotal = total;
  },
  setOpeList(state, list) {
    state.opeList = list;
  },
  setPotionSearchParams(state, searchParams) {
    state.potionSearchParams = { ...state.potionSearchParams, ...searchParams };
  },
  setPoTotal(state, total) {
    state.poTotal = total;
  },
  setPoList(state, list) {
    state.poList = list;
  },
  setHistorySearchParams(state, searchParams) {
    state.historySearchParams = {
      ...state.historySearchParams,
      ...searchParams,
    };
  },
  setHistoryTotal(state, total) {
    state.historyTotal = total;
  },
  setHistoryList(state, list) {
    state.historyList = list;
  },
  setWarningSearchParams(state, searchParams) {
    state.warningSearchParams = {
      ...state.warningSearchParams,
      ...searchParams,
    };
  },
  setWarningTotal(state, total) {
    state.warningTotal = total;
  },
  setWarningList(state, list) {
    state.warningList = list;
  },
  setImageList(state, list) {
    state.imgList = list;
  },
  setPathParams(state, list) {
    state.pathLsit = list;
  },
  setImageSearchParams(state, searchParams) {
    state.imgSearchParams = {
      ...state.imgSearchParams,
      ...searchParams,
    };
  },
  setPathSearchParams(state, searchParams) {
    state.pathSearchParams = {
      ...state.pathSearchParams,
      ...searchParams,
    };
  },
  setPathList(state, list) {
    state.pathList = list;
  },
  setCompanyList(state, { list, page_index }) {
    if (page_index === 1) {
      state.companyListData = list;
    } else {
      state.companyListData = [...state.companyListData, ...list];
    }
  },
  setCompanyTotal(state, value) {
    state.companyTotal = value;
  },
  setCarTypeList(state, value) {
    state.carTypeListData = value;
  },
  setCarList(state, value) {
    state.carListData = value;
  },
  setCarWarningList(state, value) {
    state.carWarnListData = value;
  },
};

const actions = {
  /**
   * 统计列表
   */
  getMapList(state, payload) {
    return new Promise((resolve, reject) => {
      mapList(payload)
        .then((res) => {
          const { err_code, err_data } = res;
          if (err_code === 200) {
            resolve(err_data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //车辆统计数据
  getSummary(state, payload) {
    return new Promise((resolve, reject) => {
      summary(payload)
        .then((res) => {
          const { err_code, err_data } = res;
          if (err_code === 200) {
            resolve(err_data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //车辆列表
  getSummaryList(state, payload) {
    return new Promise((resolve, reject) => {
      summaryList(payload)
        .then((res) => {
          const { err_code, err_data } = res;
          if (err_code === 200) {
            resolve(err_data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  /**
   * 单位列表
   */
  getCompanyListAgv({ commit, state }, params) {
    return new Promise((resolve) => {
      companyList(params).then((res) => {
        if (res.err_code === 200) {
          const data = res.err_data;
          const { list, total } = data;
          commit("setCompanyList", {
            list: list || [],
            page_index: params.page_index,
          });
          commit("setCompanyTotal", total);
          resolve(data);
        }
      });
    });
  },
  /**
   * 车辆类型列表列表
   */
  getCarTypeList({ commit, state }, params) {
    return new Promise((resolve) => {
      carTypeList(params).then((res) => {
        if (res.err_code === 200) {
          const data = res.err_data;
          commit("setCarTypeList", data || []);
          resolve(data);
        }
      });
    });
  },
  /**
   * 车辆列表列表
   */
  getCarList({ commit, state }, params) {
    return new Promise((resolve) => {
      carList(params).then((res) => {
        if (res.err_code === 200) {
          const data = res.err_data;
          commit("setCarList", data || []);
          resolve(data);
        }
      });
    });
  },
  /**
   * 警告列表
   */
  getWarnList(state) {
    state.commit("setLoading", true);
    state.commit("setWarningTotal", 0);
    state.commit("setWarningList", []);
    return new Promise((resolve) => {
      carWarn(state.state.warningSearchParams)
        .then((res) => {
          const { err_code, err_data } = res;
          if (err_code === 200) {
            const { total, list } = err_data || {};
            state.commit("setWarningTotal", total || 0);
            state.commit("setWarningList", list || []);
          }
          resolve(res);
        })
        .finally(() => {
          state.commit("setLoading", false);
        });
    });
    // return new Promise((resolve) => {
    //   carWarn(params).then((res) => {
    //     if (res.err_code === 200) {
    //       const data = res.err_data;
    //       // commit('SET_CAR_WARN_LIST', data);
    //       resolve(data);
    //     }
    //   });
    // });
  },
  //历史轨迹列表
  getPathList(state) {
    // console.log(state);
    state.commit("setLoading", true);
    state.commit("setPathTotal", 0);
    state.commit("setPathList", []);
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve) => {
      historyAppear(state.state.pathSearchParams)
        .then((res) => {
          const { err_code, err_data } = res;
          if (err_code === 200) {
            const { total, list } = err_data || {};
            state.commit("setPathTotal", total || 0);
            state.commit("setPathList", list || []);
          }
          resolve(res);
        })
        .finally(() => {
          state.commit("setLoading", false);
        });
    });
  },
  //图像列表
  getImageList(state) {
    // console.log(state);
    state.commit("setLoading", true);
    state.commit("setImageTotal", 0);
    state.commit("setImageList", []);
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve) => {
      imgList(state.state.imgSearchParams)
        .then((res) => {
          const { err_code, err_data } = res;
          if (err_code === 200) {
            const { total, list } = err_data || {};
            if (total > 0) {
              const newList = list.filter((item) =>
                item.img_url.startsWith("https")
              );
              state.commit("setImageTotal", total || 0);
              state.commit("setImageList", newList || []);
            }
          }
          resolve(res);
        })
        .finally(() => {
          state.commit("setLoading", false);
        });
    });
  },
  /**
   * 警告列表
   */
  getCarStatusInfo({ commit, state }, params) {
    state.commit("setLoading", true);
    state.commit("setWarningTotal", 0);
    state.commit("setWarningList", []);
    return new Promise((resolve, reject) => {
      carStatusInfo(params).then((res) => {
        if (res.err_code === 200) {
          const data = res.err_data || [];
          // commit('SET_CAR_WARN_LIST', data);
          resolve(data);
        }
      });
    });
  },
  /**
   * 获取车辆历史
   */
  getTrackList({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      trackList(params).then((res) => {
        if (res.err_code === 200) {
          const data = res.err_data || [];
          // commit('SET_CAR_WARN_LIST', data);
          resolve(data);
        }
      });
    });
  },
  getAgvAlarmList(state) {
    // console.log(state);
    state.commit("setLoading", true);
    state.commit("setOpeTotal", 0);
    state.commit("setOpeList", []);
    return new Promise((resolve) => {
      agvAlarmList(state.state.opeSearchParams)
        .then((res) => {
          const { err_code, err_data } = res;
          if (err_code === 200) {
            const { total, list } = err_data || {};
            state.commit("setOpeTotal", total || 0);
            state.commit("setOpeList", list || []);
          }
          resolve(res);
        })
        .finally(() => {
          state.commit("setLoading", false);
        });
    });
  },
  getAgvPotionList(state, type) {
    console.log(type);
    state.commit("setLoading", true);
    state.commit("setPoTotal", 0);
    state.commit("setPoList", []);
    return new Promise((resolve) => {
      //根据车类型判断无人车，保姆车
      const carTypeApi =
        Number(type) === 6902
          ? Vue.prototype.$api.CarProtectMa.recordDayList
          : Number(type) === 6901
          ? Vue.prototype.$api.CarDriverless.recordDayList
          : null;
      if (!carTypeApi) {
        state.commit("setPoTotal", 0);
        state.commit("setPoList", []);
        state.commit("setLoading", false);
        return;
      }
      carTypeApi(state.state.potionSearchParams)
        .then((res) => {
          const { err_code, err_data } = res;
          if (err_code === 200) {
            const { total, list } = err_data || {};
            state.commit("setPoTotal", total || 0);
            state.commit("setPoList", list || []);
          }
          resolve(res);
        })
        .finally(() => {
          state.commit("setLoading", false);
        });
    });
  },
  getHistoryList(state) {
    // console.log(state);
    state.commit("setLoading", true);
    state.commit("setHistoryTotal", 0);
    state.commit("setHistoryList", []);
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve) => {
      agvHistoryList(state.state.historySearchParams)
        .then((res) => {
          const { err_code, err_data } = res;
          if (err_code === 200) {
            const { total, list } = err_data || {};
            state.commit("setHistoryTotal", total || 0);
            state.commit("setHistoryList", list || []);
          }
          resolve(res);
        })
        .finally(() => {
          state.commit("setLoading", false);
        });
    });
  },
  // getPathList(state) {
  //   // console.log(state);
  //   state.commit("setLoading", true);
  //   state.commit("setPathTotal", 0);
  //   state.commit("setPathList", []);
  //   // return new Promise((resolve) => {
  //   //   pathListApi(state.state.pathSearchParams)
  //   //     .then((res) => {
  //   //       const { err_code, err_data } = res;
  //   //       if (err_code === 200) {
  //   //         const { total, list } = err_data || {};
  //   //         state.commit("setPathTotal", total || 0);
  //   //         state.commit("setPathList", list || []);
  //   //       }
  //   //       resolve(res);
  //   //     })
  //   //     .finally(() => {
  //   //       state.commit("setLoading", false);
  //   //     });
  //   // });
  // },
};

export default {
  state,
  mutations,
  actions,
};
