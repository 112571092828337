import * as echarts from "echarts/core";

const MonitorScreenDurationChart = (data) => {
  const defaultConfig = {
    legend: {
      show: true,
      top: "5%",
      right: "3%",
      textStyle: {
        color: "#fff",
        fontSize: 12,
      },
      icon: "square",
      itemWidth: 10,
      itemHeight: 10,
    },
    grid: {
      left: 0,
      right: 0,
      top: '20%',
      bottom: 0,
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: data.xData,
      axisLine: {
        lineStyle: {
          color: "#6D7A80FF",
        },
      },
      axisLabel: {
        color: "rgba(255,255,255,0.7)",
        fontSize: 12,
        interval: 0,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      min: 0,
      name: "单位:h",
      nameTextStyle: {
        color: 'rgba(255,255,255,0.5)',
        padding: [2, 2, 2, -10],
      },
      splitLine: {
        lineStyle: {
          color: "#6D7A80FF",
          type: "line",
        },
      },
      axisLabel: {
        fontSize: 12,
        color: 'rgba(255, 255, 255, 0.6)',
      },
    },
    tooltip: {
      trigger: "axis",
      backgroundColor: "rgba(1,173,249,0.2)",
      borderColor: "#47FFFF",
      borderWidth: 1,
      textStyle: {
        color: "#fff",
        fontSize: 14,
        fontWeight: 500,
        align: "left",
      },
      axisPointer: {
        type: "shadow",
        shadowStyle: {
          shadowBlur: 1,
        },
      },
    },
    series: [{
        name: "作业时长",
        type: "pictorialBar",
        barWidth: 40,
        symbol: "triangle",
        symbolSize: ["50%", "100%"],
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#01ADF9'
            },
            {
              offset: 1,
              color: 'rgba(1,173,249,0.2)'
            }
          ])
        },
        data: data.yData0,
        animationDurationUpdate: 1000, // Animation duration
        animationEasingUpdate: 'elasticOut', // Animation easing
      },
      {
        name: "喷药时长",
        type: "pictorialBar",
        symbol: "triangle",
        symbolSize: ["50%", "100%"],
        barWidth: 40,
        barGap: "-75%",
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#47FFFF'
            },
            {
              offset: 1,
              color: 'rgba(71,255,255,0.2)'
            }
          ])
        },
        data: data.yData1,
        animationDurationUpdate: 1000, // Animation duration
        animationEasingUpdate: 'elasticOut', // Animation easing
      },
    ],
    animationDurationUpdate: 1000, // Overall chart animation duration
    animationEasingUpdate: 'elasticOut', // Overall chart animation easing
  };

  return defaultConfig;
};

export default {
  MonitorScreenDurationChart,
};