import * as echarts from "echarts/core";
const MonitorScreenProductChart = (data) => {
  let legendData = [{
      name: '在线',
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: '#47FFFF' // 0% 处的颜色
        }, {
          offset: 1,
          color: 'rgba(30,231,231,0)' // 100% 处的颜色
        }])
      },
    },
    {
      name: '离线',
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: '#01ADF9' // 0% 处的颜色
        }, {
          offset: 1,
          color: 'rgba(1,173,249,0)' // 100% 处的颜色
        }]),
      },
    }
  ];
  let color = [{
      type: 'linear',
      x: 0,
      x2: 1,
      y: 0,
      y2: 0,
      colorStops: [{
          offset: 0,
          color: 'rgba(41, 241, 250, 0.6)'
        },
        {
          offset: 0.5,
          color: 'rgba(41, 241, 250, 0.6)'
        },
        {
          offset: 0.5,
          color: 'rgba(41, 241, 250, 1)'
        },
        {
          offset: 1,
          color: 'rgba(41, 241, 250, 1)'
        }
      ]
    },
    {
      type: 'linear',
      x: 0,
      x2: 1,
      y: 0,
      y2: 0,
      colorStops: [{
          offset: 0,
          color: 'rgba(1, 173, 249, 0.5)'
        },
        {
          offset: 0.5,
          color: 'rgba(1, 173, 249, 0.5)'
        },
        {
          offset: 0.5,
          color: 'rgba(1, 173, 249, 1)'
        },
        {
          offset: 1,
          color: 'rgba(1, 173, 249,1)'
        }
      ]
    },
    {
      type: 'linear',
      x: 0,
      x2: 1,
      y: 0,
      y2: 0,
      colorStops: [{
          offset: 0,
          color: 'rgba(0,188,255,0)'
        },
        {
          offset: 0.5,
          color: 'rgba(0,188,255,0)'
        },
        {
          offset: 0.5,
          color: 'rgba(0,138,186,0)'
        },
        {
          offset: 1,
          color: 'rgba(0,138,186,0)'
        }
      ]
    }
  ];
  let barWidth = 19;
  var xBar1 = [];
  var xBar2 = [];
  var xBar3 = [];
  let data1 = data.yData0
  let data2 = data.yData1
  let val = {
    data1,
    data2
  }
  for (let i = 0; i < val.data1.length; i++) {
    xBar1.push(Number(val.data2[i]));
    xBar2.push(Number(val.data1[i]) + Number(val.data2[i]));
    xBar3.push(Number(val.data1[i]));
  };
  const defaultConfig = {
    backgroundColor: "transparent",
    tooltip: {
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
        fontSize: 14
      },
      backgroundColor: "rgba(1,173,249,0.2)",
      borderColor: "#47FFFF",
      trigger: "axis",
      axisPointer: {
        type: "shadow",
        shadowStyle: {
          shadowBlur: 1,
        },
      },
      formatter: function (params) {
        let tooltipString = params[0].name + "<br/>";
        params.forEach(function (item) {
          if (item.componentSubType == "bar") {
            var icon =
              '<span style="display:inline-block;margin-right:5px;width:6px;height:6px;border-radius:50%;background-color:' +
              item.color.colorStops[3].color +
              '"></span>';
            var seriesName =
              '<span style="color: rgba(255,255,255,0.85);margin-right:30px;font-size:12px;">' +
              item.seriesName +
              "</span>";
            var value =
              '<span style="color:rgba(255,255,255,0.85);font-size:12px;">' +
              item.value +
              "</span>";
            tooltipString +=
              '<div style="padding: 2px; margin-bottom: 1px; border-radius: 4px;">' +
              icon +
              seriesName +
              value +
              "</div>";
          }
        });
        tooltipString += "</div>";
        return tooltipString;
      },
    },
    //图例渐变
    legend: {
      show: true,
      icon: "rect",
      itemWidth: 10,
      itemHeight: 10,
      top: "5%",
      right: "3%",
      textStyle: {
        color: "rgba(212, 255, 253, 1)",
        fontSize: 12,
      },
      data: legendData
    },
    grid: {
      left: 0,
      right: 0,
      top: '20%',
      bottom: 0,
      containLabel: true,
    },
    xAxis: [{
      axisLine: {
        lineStyle: {
          color: "#6D7A80FF",
        },
      },
      axisTick: {
        show: false
      },
      splitArea: {
        show: false
      },
      data: data.xData,
      axisLabel: {
        show: true,
        textStyle: {
          color: "rgba(255,255,255,0.7)",
          fontSize: 12,
          interval: 0
        },
        formatter: function (value) {
          if (value.length > 5) {
            return `${value.slice(0, 5)}...`
          }
          return value
        }
      },
    }],
    title: {
      text: "单位:辆",
      top: 12,
      left: 0,
      textStyle: {
        color: '#A8D6FF',
        fontSize: 12
      },
    },
    yAxis: {
      type: "value",
      min: 0,
      minInterval: 1,
      splitLine: {
        lineStyle: {
          color: "#6D7A80",
          type: "dotted",
        },
      },
      axisLabel: {
        fontSize: 12,
        color: 'rgba(168,214,255,0.7)',
      },
    },
    series: [
      // data2本体
      {
        z: 1,
        name: legendData[1].name,
        type: 'bar',
        barWidth: barWidth,
        stack: 'bar',
        color: color[1],
        data: val.data2
      },
      // data1本体
      {
        z: 2,
        name: legendData[0].name,
        type: 'bar',
        barWidth: barWidth,
        stack: 'bar',
        color: color[0],
        data: val.data1
      },
      // 底部立体
      {
        z: 3,
        name: '底部立体',
        type: 'pictorialBar',
        data: xBar1,
        symbol: 'diamond',
        symbolOffset: ['0%', '50%'],
        symbolSize: [barWidth, 10],
        itemStyle: {
          normal: {
            color: color[1]
          }
        },
        tooltip: {
          show: false
        }
      },
      // data2离线头部
      {
        z: 5,
        name: legendData[1].name,
        type: 'pictorialBar',
        symbolPosition: 'end',
        itemStyle: {
          normal: {
            color: color[1]
          }
        },
        data: xBar1,
        symbol: 'diamond',
        symbolOffset: ['0%', '-50%'],
        symbolSize: [barWidth - 4, (10 * (barWidth - 4)) / barWidth],
        tooltip: {
          show: false
        }
      },
      // data1在线头部
      {
        z: 4,
        name: legendData[0].name,
        type: 'pictorialBar',
        data: xBar2,
        symbol: 'diamond',
        symbolPosition: 'end',
        symbolOffset: ['0%', '-50%'],
        symbolSize: [barWidth, 10],
        itemStyle: {
          normal: {
            color: color[0]
          }
        },
        tooltip: {
          show: false
        }
      },

    ],
  };

  const opt = Object.assign({}, defaultConfig);
  return opt;
};

export default {
  MonitorScreenProductChart,
};