import request from "@utils/request";

// RTK参数管理
export default {
    rtkSetList(params) {
        return request({
            url: "/car/rtk_setting/list",
            method: "get",
            params,
        });
    },

    delRtkSet(data) {
        return request({
            url: "/car/rtk_setting/del",
            method: "delete",
            data,
        });
    },

    infoRtkMa(params) {
        return request({
            url: "/car/rtk_setting/info",
            method: "get",
            params,
        });
    },

    addRtkMa(data) {
        return request({
            url: "/car/rtk_setting/add",
            method: "post",
            data,
        });
    },

    updateRtkMa(data) {
        return request({
            url: "/car/rtk_setting/edit",
            method: "put",
            data,
        });
    },

};