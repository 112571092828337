import * as echarts from "echarts/core";

const BigScreenProductChart = (data) => {
  const seriesData1 = data.yData1; //离线
  const seriesData2 = data.yData0; //在线
  // const seriesData3 = seriesData2.map((o, i) => o + seriesData1[i]);
  let defaultShowNum = 3; // 默认显示的数据条数3
  let start = 0;
  let end = Math.min(defaultShowNum, data.xData.length) / data.xData.length * 100;
  const defaultConfig = {
    tooltip: {
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
        fontSize: 9
      },
      backgroundColor: "rgba(24,144,255,0.3)", //背景色
      borderColor: "rgba(24,144,255,0.3)", // 边框颜色
      trigger: "axis",
      // axisPointer: {
      //   type: "shadow",
      // },
      formatter: function (params) {
        console.log(`output->params,'params`, params)
        let tooltipString = params[0].name + "<br/>";
        params.forEach(function (item) {
          if (item.componentSubType == "bar") {
            var icon =
              '<span style="display:inline-block;margin-right:5px;width:6px;height:6px;border-radius:50%;background-color:' +
              item.borderColor +
              '"></span>';
            var seriesName =
              '<span style="color: rgba(255,255,255,0.85);margin-right:20px;font-size:8px;">' +
              item.seriesName +
              "</span>";
            var value =
              '<span style="color:rgba(255,255,255,0.85);font-size:8px;">' +
              item.value +
              "</span>";
            tooltipString +=
              '<div style="padding: 2px; margin-bottom: 1px; border-radius: 4px;">' +
              icon +
              seriesName +
              value +
              "</div>";
          }
        });
        tooltipString += "</div>";
        return tooltipString;
      },
    },
    dataZoom: [{
      type: "slider", //slider表示有滑动块的，inside表示内置的
      show: data.xData.length > 3 ? true : false,
      backgroundColor: "rgba(112, 112, 112, 0.23)", //组件的背景颜色
      height: 6,
      xAxisIndex: 0,
      bottom: 15,
      start: start,
      end: end,
      handleSize: 6,
      minSpan: 20, //最小滑动距离
      showDetail: false,
      filterMode: "filter",
      borderColor: "rgba(40, 107, 228, 0.10)", //边框颜色
      fillerColor: "rgba(40, 107, 228, 0.20)", //选中范围背景色
      moveOnMouseMove: true,
      selectedDataBackground: {
        lineStyle: {
          color: "rgba(112, 112, 112, 0.33)",
        },
        areaStyle: {
          color: "#fff",
          shadowColor: "rgba(0, 0, 0, 0.3)",
          shadowBlur: 5,
        },
      },
      // 用于画手柄
      handleIcon:
        // "M0,0 v9.7h5 v-9.7h-5 Z", // 画一个长方形
        "path://M30.9,53.2C16.8,53.2,5.3,41.7,5.3,27.6S16.8,2,30.9,2C45,2,56.4,13.5,56.4,27.6S45,53.2,30.9,53.2z M30.9,3.5M36.9,35.8h-1.3z M27.8,35.8 h-1.3H27L27.8,35.8L27.8,35.8z", // 画一个圆形
      handleStyle: {
        color: "rgba(112, 112, 112, 0.33)",
        global: true, // 缺省为 false
        shadowBlur: 10,
        shadowColor: "rgba(123, 154, 204, 0.5)",
        shadowOffsetX: 0, // 阴影偏移x轴多少
        shadowOffsetY: 0, // 阴影偏移y轴多少
      },
    }, ],
    //图例渐变
    legend: {
      icon: "rect",
      itemWidth: 10,
      itemHeight: 10,
      top: "5%",
      right: "3%",
      textStyle: {
        color: "rgba(212, 255, 253, 1)",
        fontSize: 12,
      },
      data: [{
          name: '在线',
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#47FFFF' // 0% 处的颜色
            }, {
              offset: 1,
              color: 'rgba(30,231,231,0)' // 100% 处的颜色
            }])
          },
        },
        {
          name: '离线',
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#01ADF9' // 0% 处的颜色
            }, {
              offset: 1,
              color: 'rgba(1,173,249,0)' // 100% 处的颜色
            }]),
          },
        }
      ]
    },

    grid: {
      left: '1%',
      right: 0,
      top: '25%',
      bottom: '13%',
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: data.xData,
      axisLine: {
        lineStyle: {
          color: 'rgba(168,214,255,0.7)',
        },
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: 'rgba(168,214,255,0.7)',
          fontSize: 10,
          interval: 0
        },
        formatter: function (value) {
          if (value.length > 5) {
            return `${value.slice(0, 5)}...`
          }
          return value
        }
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      min: 0,
      minInterval: 1,
      name: "单位:辆",
      nameTextStyle: {
        color: ' #A8D6FF',
        padding: [2, 2, 2, 2],
        fontSize: 12,
      },
      splitLine: {
        lineStyle: {
          color: "rgba(40, 107, 220, 0.20)",
          type: "dotted",
        },
      },
      axisLabel: {
        fontSize: 12,
        color: 'rgba(168,214,255,0.7)',
      },
    },
    series: [{
        name: "离线",
        data: seriesData1,
        barWidth: 20,
        type: 'bar',
        stack: 'bar',
        itemStyle: {
          borderColor: "rgba(67, 120, 219, 0.6)",
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [{
                offset: 0,
                color: 'rgba(67, 120, 219, 0.6)'
              },
              {
                offset: 0.5,
                color: 'rgba(67, 120, 219, 0.6)'
              },
              {
                offset: 0.5,
                color: 'rgba(67, 120, 219, 1)'
              },
              {
                offset: 1,
                color: 'rgba(67, 120, 219, 1)'
              }
            ],
            global: false
          },
        }
      },
      // {
      //   //中间菱形
      //   type: 'pictorialBar',
      //   barWidth: 20,
      //   data: seriesData1,
      //   symbol: 'diamond',
      //   symbolSize: ['100%', 8],
      //   symbolPosition: 'end',
      //   symbolOffset: ['0', -4],
      //   itemStyle: {
      //     color: '#29F1FA',
      //     shadowColor: '#29F1FA',
      //     shadowBlur: 10,
      //     shadowOffsetY: 4
      //   },
      //   z: 5
      // },
      {
        name: "在线",
        data: seriesData2,
        barWidth: 20,
        type: 'bar',
        stack: 'bar',
        itemStyle: {
          borderColor: "rgba(41, 241, 250, 1)",
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [{
                offset: 0,
                color: 'rgba(41, 241, 250, 0.6)'
              },
              {
                offset: 0.5,
                color: 'rgba(41, 241, 250, 0.6)'
              },
              {
                offset: 0.5,
                color: 'rgba(41, 241, 250, 1)'
              },
              {
                offset: 1,
                color: 'rgba(41, 241, 250, 1)'
              }
            ],
            global: false
          },
        }
      },
      // {
      //   //最上面菱形
      //   type: 'pictorialBar',
      //   barWidth: 20,
      //   data: seriesData3,
      //   symbol: 'diamond',
      //   symbolSize: ['100%', 8],
      //   symbolPosition: 'end',
      //   symbolOffset: ['0', -4],
      //   itemStyle: {
      //     color: '#29F1FA',
      //     shadowColor: '#29F1FA',
      //     shadowBlur: 10,
      //     shadowOffsetY: 4
      //   },
      //   z: 5
      // }
    ],
  };

  const opt = Object.assign({}, defaultConfig);
  return opt;
};

export default {
  BigScreenProductChart,
};