import request from "@utils/request";

export default {
  // 列表
  carMmaintenanceList(data) {
    return request({
      url: "/car_maintenance/list",
      method: "get",
      params: data,
    });
  },
	// 详情
	carMmaintenanceInfo(data) {
	  return request({
	    url: "/car_maintenance/info",
	    method: "get",
	    params: data,
	  });
	},
	// 导出
	carMmaintenanceExport(params) {
	  return request({
	    url: "/car_maintenance/export/excel",
	    method: "get",
			params: params,
			responseType: "blob"
	  });
	},
};