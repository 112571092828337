/**
 * storage
 */
export default {
  setItem(key, val) {
    window.localStorage.setItem(key, JSON.stringify(val));
  },
  getItem(key) {
    return window.localStorage.getItem(key) ? JSON.parse(window.localStorage.getItem(key)) : null;
  },
  /* getStorage(key) {
    return JSON.parse(window.localStorage.getItem(key) || "{}");
  }, */
  clearItem(key) {
    window.localStorage.setItem(key, "{}");
  },
	removeItem(key){
		window.localStorage.removeItem(key);
	},
  clearAll() {
    window.localStorage.clear();
  },
};
