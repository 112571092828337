import request from "@utils/request";
import axios from "axios";

// 查询所有系统配置
export default {
  allList(data) {
    return request({
      url: "/agvCar/all",
      method: "get",
      params: data,
    });
  },
  agvCarAdd(data) {
    return request({
      url: "/agvCar/add",
      method: "post",
      data,
    });
  },
  agvCarEdit(data) {
    return request({
      url: "/agvCar/edit",
      method: "put",
      data,
    });
  },
  deleteAgvCar(data) {
    return request({
      url: "/agvCar/del",
      method: "delete",
      data,
    });
  },
  workList(data) {
    return request({
      url: "/agvCar/work/list",
      method: "GET",
      params: data,
    });
  },
  workInfo(data) {
    return request({
      url: "/agvCar/work/info",
      method: "GET",
      params: data,
    });
  },
  getLatLngJSON(url) {
    return axios.get(
      process.env.NODE_ENV === "production"
        ? url
        : process.env.VUE_APP_BASE_JSON_API + url
    );
  },
  recordDayList(data) {
    return request({
      url: "/agvCar/potion/record/day/list",
      method: "GET",
      params: data,
    });
  },
  recordList(data) {
    return request({
      url: "/agvCar/potion/record/list",
      method: "GET",
      params: data,
    });
  },
  //删除配药记录
  delRecordList(data) {
    return request({
      url: "/agvCar/potion/record/del",
      method: "delete",
      data,
    });
  },
  //删除轨迹
  delWork(data) {
    return request({
      url: "/agvCar/work/del",
      method: "DELETE",
      data,
    });
  },

  lsWorkList(data) {
    return request({
      url: "/agvCar/work/ls/list",
      method: "GET",
      params: data,
    });
  },
  lsWorkInfo(data) {
    return request({
      url: "/agvCar/work/ls/info",
      method: "GET",
      params: data,
    });
  },

  lsWorkDel(data) {
    return request({
      url: "/agvCar/work/ls/del",
      method: "DELETE",
      data,
    });
  },
};

export function carType(query) {
  return request({
    url: "/dictionary/get/13",
    method: "get",
    params: query,
  });
}

//联适

// export function lsWorkList(data) {
//   return request({
//     url: "/agvCar/work/ls/list",
//     method: "GET",
//     params: data,
//   });
// }
// export function lsWorkInfo(data) {
//   return request({
//     url: "/agvCar/work/ls/info",
//     method: "GET",
//     params: data,
//   });
// }

// export function lsWorkDel(data) {
//   return request({
//     url: "/agvCar/work/ls/del",
//     method: "DELETE",
//     data,
//   });
// }
