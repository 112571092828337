// import { fetchPermission } from "../../api/index";
import Vue from "vue";
import router, { /* router, */ dynamicRoutes } from "@/router/index";
import dynamicRouter from "@/router/dynamic-router";
import { recursionRouter, setDefaultRoute } from "@/utils/recursion-router";
import { recursionMenuList } from "@/utils/tools";
import storage from "@/utils/storage";
export default {
  namespaced: true,
  state: {
    permissionList: null,
    permissionListCodes: [],
    sidebarMenu: [], // 导航菜单
    sidebarSecondMenu: storage.getItem("secondMenu") || [], // 二级菜单
    currentMenu: "", // 高亮
  },
  getters: {},
  mutations: {
    SET_PERMISSION(state, routes) {
      state.permissionList = routes;
    },
    SET_PERMISSION_CODES(state, routes) {
      state.permissionListCodes = routes;
    },
    CLEAR_PERMSSION(state) {
      state.permissionList = null;
      state.permissionListCodes = [];
    },
    SET_MENU(state, menu) {
      state.sidebarMenu = menu;
    },
    SET_SECOND_MENU(state, value) {
      state.sidebarSecondMenu = value;
      storage.setItem("secondMenu", value);
    },
    CLEAR_SECOND_MENU(state) {
      storage.removeItem("secondMenu");
      state.sidebarSecondMenu = {};
    },
    CLEAR_MENU(state) {
      state.sidebarMenu = [];
    },
  },
  // 异步访问
  actions: {
    // eslint-disable-next-line no-unused-vars
    async FETCH_PERMISSION({ commit, state }) {
      let { err_data } = await Vue.prototype.$api.MenuRole.policyMy();
      let permissionList = err_data || [];
      const track = (list) => {
        if (!Array.isArray(list)) {
          return [];
        }
        let arr = [];
        list.forEach((item) => {
          if (item.code === "management") {
            arr = item.child_menu;
          }
          if (arr.length <= 0 && item.child_menu && item.child_menu.length) {
            arr = track(item.child_menu);
          }
        });
        return arr;
      };

      let newPermissionList = track(permissionList);

      // 筛选
      let routes = recursionRouter(newPermissionList, dynamicRouter);

      let MainContainer = dynamicRoutes.find((v) => !v.meta.hideMenu);
      let children = MainContainer.children;

      children.splice(0, children.length); //fix测试要放开

      children.push(...routes);

      // 生成菜单

      commit("SET_MENU", recursionMenuList(children));
      console.log(recursionMenuList(children));
      // 设置默认路由
      setDefaultRoute([MainContainer]);
      // 初始化路由
      let initialRoutes = router.options.routes;
      router.addRoutes(dynamicRoutes);
      commit("SET_PERMISSION", [...initialRoutes]);
      commit("SET_PERMISSION_CODES", permissionList);
      // commit("SET_PERMISSION",initialRoutes)
    },
    // eslint-disable-next-line no-unused-vars
  },
};
