const soleRatioChart = (data) => {
    const defaultConfig =  {
        tooltip: {
          trigger: "item",
        },
        legend: [
          {
            show: false,
            orient: "vertical",
            // data: arr,
            icon: "circle",
            left: "right",
            top: "center",
            align: "left",
            itemGap: 30,
            textStyle: {
              color: "#4E5969",
              fontSize: 12,
            },
            //图例标记的图形高度
            itemHeight: 10,
            //图例标记的图形宽度
            itemWidth: 10,
          },
        ],
        series: [
          {
            name: "车辆销售渠道占比",
            type: "pie",
            clockwise: false,
            radius: ["0%", "100%"],
            width: "80%",
            height: "70%",
            emphasis: {
              scale: false,
            },
            center: ["50%", "50%"],
            top: "center",
            data: data.resData,
          },
        ],
      };

    const opt = Object.assign({}, defaultConfig);
    return opt;
  };
  
  export default {
    soleRatioChart,
  };
  