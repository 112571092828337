<template>
  <head-com />
</template>

<script>
// import Hamburger from "@/components/Hamburger";
import HeadCom from "./HeadCom.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "Topbar",
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    // Hamburger,
    HeadCom,
  },
  data() {
    return {
      // routes: constantRoutes
      systemName: "sass云平台管理端",
    };
  },
  computed: {
    device() {
      return this.$store.state.app.device;
    },

    sidebar() {
      return this.$store.state.app.sidebar;
    },
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  mounted() {
    // this.initCurrentRoutes();
  },
  methods: {
    ...mapActions(["logout"]),
    //切换sideBar
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    //登出功能
    exit() {
      this.$confirm({
        title: "提示",
        content: "确定注销并退出系统吗？",
        centered: true,
        onOk: () => {
          this.logout().then(() => {
            this.$router.push("/login");
          });
        },
        onCancel: () => {},
      });
    },
  },
};
</script>

<style>
.hamburger {
  fill: currentColor;
  color: #fff;
}
</style>

<style lang="less" scoped>
.hamburger-container {
  line-height: 65px;
  height: 100%;
  float: left;
  cursor: pointer;
  transition: background 0.3s;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background: rgba(0, 0, 0, 0.025);
  }
}
</style>
