<template>
  <section class="app-main" style="min-height: 100%">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
  </section>
</template>

<script>
export default {
  name: "AppMain",
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/mixin.less";
.app-main {
  background: #F3F3F3;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
}
&-position {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
</style>
