<template>
  <div class="search-template flex flex-column" ref="searchTemplate">
    <div
      class="search-template-content"
      :style="
        'max-height:' + (fold ? maxHeight + 'px' : foldRow * rowHeight + 'px')
      "
    >
      <div class="search-form">
        <div class="search-input" ref="searchInput">
          <slot name="search-input"></slot>
        </div>
        <div class="search-btns" ref="searchBtns">
          <slot name="search-btns"></slot>
        </div>
      </div>
    </div>
    <div
      class="fold flex justify-center align-center f-s-13 pointer"
      v-if="maxHeight > foldRow * rowHeight"
      @click="onFold"
    >
      {{ fold ? "收起" : "展开" }}
      <a-icon
        :type="fold ? 'up' : 'down'"
        style="margin-left: 5px; font-size: 10px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchTemplate",
  props: {
    foldRow: {
      type: Number,
      default: 1,
    },
    rowHeight: {
      type: Number,
      default: 40,
    },
  },
  data() {
    return {
      showFold: false,
      maxHeight: 40,
      fold: false,
      searchHeight: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.wResize();
      window.addEventListener("resize", this.wResize);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.wResize);
  },
  updated() {
    this.wResize();
  },
  methods: {
    wResize() {
      const searchInputH = this.$refs.searchInput.offsetHeight;
      const searchBtnsH = this.$refs.searchBtns.offsetHeight;
      const maxHeight =
        searchInputH >= searchBtnsH ? searchInputH : searchBtnsH;
      this.maxHeight = maxHeight;
    },
    // 展开收起
    onFold() {
      this.fold = !this.fold;
      this.wResize();
      setTimeout(() => {
        this.searchHeight = this.$refs.searchTemplate.offsetHeight;
        this.$emit("getSearchHt", this.searchHeight);
      }, 200);
    },
  },
};
</script>

<style lang="less" scoped>
.search-template {
  border-bottom: 1px solid #f3f3f3;
  padding: 16px;
  position: relative;
  &-content {
    overflow: hidden;
    transition: max-height 0.2s;
    .search-form {
      display: flex;
      .search-input {
        flex: 1;
      }
      .search-btns {
        margin-left: 10px;
        margin-top: 4px;
      }
    }
  }
  .fold {
    color: #4378db;
    position: absolute;
    left: 50%;
    bottom: -6px;
    background: #fff;
    padding: 0 10px;
    margin-left: -30px;
  }
}
</style>
